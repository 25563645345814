<template>
  <main>
    <router-link
      :to="{ name: 'CreateFounder' }"
      class="btn btn-primary float-end"
    >
      Create Founder
    </router-link>
    <h4>Founders</h4>
    <div v-if="error" class="error">
      {{ error }}
    </div>
    <div v-if="founders">
      <div class="container marketing">
        <div class="row">
          <div class="col-lg-4" v-for="founder in founders" :key="founder.id">
            <router-link
              :to="{ name: 'FounderDetails', params: { id: founder.id } }"
            >
              <img
                class="founder-img"
                :src="founder.imageURL"
                :alt="founder.name"
              />
              <h4>{{ founder.name }}</h4>
              <h6>{{ founder.title }} (Order: {{ founder.order }})</h6>
              <p>
                {{ founder.description }}
              </p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import getCollection from "@/composables/getCollection";
export default {
  setup() {
    const { error, documents: founders } = getCollection("rhFounders", null, [
      "order",
      "asc"
    ]);
    return { error, founders };
  }
};
</script>
<style scoped>
.col-lg-4:hover {
  box-shadow: 1px 2px 3px rgba(50, 50, 50, 0.05);
  transform: scale(1.02);
  transition: all ease 0.2s;
}
a {
  text-decoration: none;
}

.founder-img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
}
</style>
