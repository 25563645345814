<template>
  <main>
    <form @submit.prevent="handleSubmit" class="shadow">
      <h3>Administrator Sign In</h3>
      <div class="form-outline mb-4">
        <input
          type="email"
          v-model="email"
          placeholder="Email Address"
          class="form-control"
        />
      </div>
      <div v-if="isPasswordShowing" class="input-group mb-4">
        <input
          type="text"
          placeholder="Password"
          v-model="password"
          class="form-control"
        />
        <span
          class="input-group-text btn btn-primary"
          @click="isPasswordShowing = false"
          ><i class="bi bi-eye-slash"></i>
        </span>
      </div>
      <div v-else class="input-group mb-4">
        <input
          type="password"
          placeholder="Password"
          v-model="password"
          class="form-control"
        />
        <span
          class="input-group-text btn btn-primary"
          @click="isPasswordShowing = true"
          ><i class="bi bi-eye"></i>
        </span>
      </div>
      <div v-if="error" class="error">{{ error }}</div>
      <button v-if="!isPending" class="btn btn-primary btn-block">
        Sign In
      </button>
      <button v-else class="btn btn-secondary btn-block" disabled>
        <div class="spinner-border" role="status"></div>
      </button>
    </form>
  </main>
</template>

<script>
import useLogin from "../../composables/useLogin";
import { ref } from "vue";
import { useRouter } from "vue-router";

export default {
  setup() {
    const isPasswordShowing = ref(false);
    const { error, login, isPending } = useLogin();
    const router = useRouter();
    const email = ref("");
    const password = ref("");

    const handleSubmit = async () => {
      await login(email.value, password.value);
      if (!error.value) {
        router.push({ name: "Issuers" });
      }
    };

    return {
      email,
      password,
      isPasswordShowing,
      handleSubmit,
      error,
      isPending
    };
  }
};
</script>

<style>
</style>