<template>
  <main>
    <div class="create-feature">
      <form @submit.prevent="handleSubmit">
        <h4>Create a Feature</h4>
        <div class="form-outline mb-4">
          <label>Headline</label>
          <input
            type="text"
            placeholder="Headline"
            v-model="headline"
            class="form-control"
            required
          />
        </div>
        <div class="form-outline mb-4">
          <label>Subheading</label>
          <input
            type="text"
            placeholder="Subheading"
            v-model="subheading"
            class="form-control"
            required
          />
        </div>
        <div class="form-outline mb-4">
          <label>Description</label>
          <textarea
            placeholder="Description"
            v-model="description"
            class="form-control"
            required
          />
        </div>
        <div class="form-outline mb-4">
          <label>Sort Order (0 not visible)</label>
          <input
            type="number"
            min="0"
            max="10"
            step="1"
            v-model="order"
            class="form-control"
            required
          />
        </div>
        <div class="form-outline mb-4">
          <div>
            <label>Upload Feature Image (500 x 500)</label>
          </div>
          <input type="file" @change="handleChange" />
        </div>
        <div class="error">{{ formError }}</div>
        <div v-if="!isPending">
          <router-link
            :to="{ name: 'Features' }"
            class="btn btn-secondary btn-block float-end"
          >
            Cancel
          </router-link>
          <button class="btn btn-primary btn-block">
            Create
          </button>
        </div>
        <div v-else>
          <button class="btn btn-secondary" disabled>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Saving...</span>
            </div>
          </button>
        </div>
      </form>
    </div>
  </main>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import useStorage from "@/composables/useStorage";
import useCollection from "@/composables/useCollection";
import useDocument from "@/composables/useDocument";
import getUser from "@/composables/getUser";
import { timestamp } from "@/firebase/config";

export default {
  setup() {
    const isPending = ref(false);
    const formError = ref(null);
    const headline = ref("");
    const subheading = ref("");
    const description = ref("");
    const order = ref(0);
    const file = ref(null);

    const { error: docError, addDoc } = useCollection("rhFeatures");
    const { error: storageError, filePath, url, uploadImage } = useStorage();
    const { user } = getUser();
    const router = useRouter();

    // allowed file types
    const types = ["image/png", "image/jpeg"];

    const handleChange = e => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        formError.value = null;
      } else {
        file.value = null;
        formError.value = "Please select an image file (png or jpeg)";
      }
    };

    const handleSubmit = async () => {
      try {
        isPending.value = true;
        formError.value = null;
        const res = await addDoc({
          headline: headline.value,
          subheading: subheading.value,
          description: description.value,
          order: parseInt(order.value),
          createdBy: user.value.uid,
          createdAt: timestamp()
        });
        if (docError.value) {
          formError.value = docError.value;
        } else {
          if (file.value) {
            await uploadImage(
              `rhFeatures/${res.id}`,
              "featureImage",
              file.value
            );
            if (!storageError.value && filePath.value && url.value) {
              const { updateDoc } = useDocument("rhFeatures", res.id);
              await updateDoc({
                imageFilePath: filePath.value,
                imageURL: url.value,
                editedAt: timestamp()
              });
              if (!docError.value) {
                router.push({
                  name: "Features"
                });
              }
              formError.value = docError.value;
            } else {
              formError.value = "Error uploading Image " + storageError.value;
            }
          }
        }
        isPending.value = false;
      } catch (err) {
        formError.value = "System Error: " + err.message;
      }
    };

    return {
      isPending,
      formError,
      headline,
      subheading,
      description,
      order,
      file,
      handleChange,
      handleSubmit
    };
  }
};
</script>

<style scoped>
form {
  max-width: 100%;
  text-align: left;
}
</style>