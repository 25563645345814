<template>
  <main>
    <form
      v-if="feature"
      @submit.prevent="handleSubmit"
      class="shadow edit-form"
    >
      <div class="float-end">
        <router-link :to="{ name: 'Features' }"><h4>&times;</h4></router-link>
      </div>
      <h4>Feature Details</h4>
      <label>Headline</label>
      <div class="form-outline mb-4">
        <input
          type="text"
          placeholder="Headline"
          v-model="feature.headline"
          class="form-control"
          required
        />
      </div>
      <div class="form-outline mb-4">
        <label>Subheading</label>
        <input
          type="text"
          class="form-control"
          placeholder="Subheading"
          v-model="feature.subheading"
          required
        />
      </div>
      <div class="form-outline mb-4">
        <label>Description</label>
        <textarea
          placeholder="Description"
          v-model="feature.description"
          class="form-control"
          required
        />
      </div>
      <div class="form-outline mb-4">
        <label>Sort Order (0 not visible)</label>
        <input
          type="number"
          min="0"
          max="10"
          step="1"
          v-model="feature.order"
          class="form-control"
          required
        />
      </div>
      <div class="form-outline mb-4">
        <div class="thumbnail">
          <img :src="feature.imageURL" />
        </div>
        <div><label>Feature Image (500 x 500)</label></div>
        <input type="file" @change="handleChange" />
      </div>
      <div class="error">{{ formError }}</div>
      <div v-if="!isPending" class="row text-center">
        <div class="col">
          <button class="btn btn-primary">Save</button>
        </div>
        <div class="col">
          <router-link :to="{ name: 'Features' }" class="btn btn-secondary">
            Cancel
          </router-link>
        </div>
        <div class="col">
          <span
            class="btn btn-link text-danger"
            data-bs-toggle="modal"
            data-bs-target="#confirmDeleteModal"
          >
            Delete Item
          </span>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-secondary" disabled>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Saving...</span>
          </div>
        </button>
      </div>
    </form>

    <ModalDelete title="Delete Feature?" @confirm-delete="handleDelete">
      Are you sure you want to permanently delete this Feature?
    </ModalDelete>
  </main>
</template>

<script>
import { useRouter } from "vue-router";
import useStorage from "@/composables/useStorage";
import getDocument from "@/composables/getDocument";
import useDocument from "@/composables/useDocument";
import { timestamp } from "@/firebase/config";
import { ref } from "@vue/reactivity";
import ModalDelete from "@/components/modals/ModalDelete.vue";

export default {
  props: ["id"],
  components: { ModalDelete },
  setup(props) {
    const formError = ref(null);
    const isPending = ref(false);
    const {
      error: storageError,
      url,
      filePath,
      uploadImage,
      deleteImage
    } = useStorage();
    const { document: feature } = getDocument("rhFeatures", props.id);
    const { error: docError, updateDoc, deleteDoc } = useDocument(
      "rhFeatures",
      props.id
    );
    const file = ref(null);
    const router = useRouter();

    const handleSubmit = async () => {
      try {
        let imageFilePath = "";
        let imageURL = "";
        isPending.value = true;
        formError.value = null;
        storageError.value = null;
        if (file.value) {
          await uploadImage(
            `rhFeatures/${feature.value.id}`,
            "featureImage",
            file.value
          );
          imageFilePath = filePath.value;
          imageURL = url.value;
        } else {
          if (feature.value.imageFilePath && feature.value.imageURL) {
            imageFilePath = feature.value.imageFilePath;
            imageURL = feature.value.imageURL;
          }
        }
        if (storageError.value) {
          formError.value = storageError.value;
        } else {
          await updateDoc({
            headline: feature.value.headline,
            subheading: feature.value.subheading,
            description: feature.value.description,
            order: parseInt(feature.value.order),
            imageFilePath: imageFilePath,
            imageURL: imageURL,
            editedAt: timestamp()
          });
          isPending.value = false;
          if (docError.value) {
            formError.value = docError.value;
          } else {
            formError.value = null;
            router.push({
              name: "Features"
            });
          }
        }
        isPending.value = false;
      } catch (err) {
        formError.value = "System Error: " + err.message;
      }
    };

    // allowed file types
    const types = ["image/png", "image/jpeg"];

    const handleChange = e => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        formError.value = null;
      } else {
        file.value = null;
        formError.value = "Please select an image file (png or jpeg)";
      }
    };

    const handleDelete = async () => {
      isPending.value = true;
      formError.value = null;
      storageError.value = null;
      if (feature.value.imageFilePath) {
        await deleteImage(feature.value.imageFilePath);
      }
      if (storageError.value) {
        formError.value = storageError.value;
      } else {
        await deleteDoc();
        if (docError.value) {
          formError.value = docError.value;
        } else {
          router.push({ name: "Features" });
        }
      }
      isPending.value = false;
    };

    return {
      formError,
      isPending,
      handleDelete,
      feature,
      handleSubmit,
      handleChange
    };
  }
};
</script>

<style scoped>
form {
  max-width: 100%;
  text-align: left;
}
.thumbnail {
  max-width: 100%;
  max-height: 400px;
  overflow: hidden;
  border-radius: 10px;
}
</style>