<template>
  <SubNav class="subnav-position" :selected="'comments'" :id="id" />
  <main>
    <div class="subnav-detail">
      <div class="float-end">
        <router-link :to="{ name: 'OfferingComments', params: { id: id } }"
          ><h4>&times;</h4></router-link
        >
      </div>
      <h6>Question / Comment</h6>
      <div v-if="offeringComment" class="row">
        <div
          class="row single"
          :class="{
            deleteRequest: offeringComment.requestDelete
          }"
        >
          <div class="col">
            <div class="subject">
              {{ offeringComment.subject }}
            </div>
            <div class="name">
              {{ offeringComment.commenterDisplayName }}
            </div>
            <div class="legal" v-if="offeringComment.isHyping">
              *issuer founder, employee, or otherwise compensated to promote
              this offering*
            </div>
            <div class="created-at">
              {{ offeringComment.date }}
            </div>
          </div>
          <div class="col comment">
            {{ offeringComment.comment }}
          </div>
          <div v-if="offeringComment.requestDelete">
            <span
              class="btn btn-primary float-end"
              @click="handleClearRequest(offeringComment.id)"
            >
              Clear Delete Request
            </span>
          </div>
        </div>
      </div>

      <div v-if="formattedReplies">
        <div class="error">{{ getReplyError }}</div>
        <h6>Replies</h6>
        <div class="reply-window">
          <div class="error">{{ formError }}</div>
          <div v-for="reply in formattedReplies" :key="reply.id">
            <div
              class="admin-reply"
              :class="{ deleteRequest: reply.requestDelete }"
            >
              <div class="d-flex justify-content-between">
                <div>
                  <div class="created-at">
                    {{ reply.createdAt }}
                  </div>
                  <div class="name">
                    {{ reply.commenterDisplayName }}
                  </div>
                  <div class="legal" v-if="reply.isHyping">
                    *issuer founder, employee, or otherwise compensated to
                    promote this offering*
                  </div>
                </div>
                <div>
                  <span
                    class="btn btn-link text-danger"
                    @click="handleDeleteReply(reply.id)"
                  >
                    Delete Reply
                  </span>
                </div>
              </div>
              <div class="d-flex justify-content-start">
                <div class="message">
                  {{ reply.comment }}
                </div>
              </div>
              <div
                v-if="reply.requestDelete"
                class="d-flex justify-content-start"
              >
                <span class="btn " @click="handleClearReplyRequest(reply.id)">
                  Clear Delete Request
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!formattedReplies && !isLoading">
        <div class="col">
          <span class="btn btn-link text-danger" @click="handleDelete">
            Delete Comment
          </span>
        </div>
      </div>
      <form v-if="!isLoading" @submit.prevent="handleReply">
        <div class="form-outline mb-4">
          <label>Reply</label>
          <textarea v-model="comment" required class="form-control long-text">
          </textarea>
        </div>
        <div v-if="!isPending" class="row text-center">
          <div class="col">
            <button class="btn btn-primary">Reply</button>
          </div>
        </div>
      </form>
    </div>
  </main>
</template>

<script>
import SubNav from "@/components/navigation/SubNav.vue";
import getDocument from "@/composables/getDocument";
import getCollection from "@/composables/getCollection";
import useCollection from "@/composables/useCollection";
import useDocument from "@/composables/useDocument";
import getUser from "@/composables/getUser.js";
import { timestamp } from "@/firebase/config";
import { ref } from "@vue/reactivity";
import { computed, onMounted } from "@vue/runtime-core";
import { formatDistanceToNow } from "date-fns";
import { useRouter } from "vue-router";

export default {
  props: ["id", "commentId"],
  components: { SubNav },
  setup(props) {
    const replyDeleteCount = ref(0);
    const isPending = ref(false);
    const formError = ref(null);
    const comment = ref("");
    const { user } = getUser();
    const router = useRouter();
    const { document: offeringComment } = getDocument(
      "offeringComments",
      props.commentId
    );
    const {
      error: useDocError,
      updateDoc: updateComment,
      deleteDoc: deleteComment
    } = useDocument("offeringComments", props.commentId);
    const {
      isLoading,
      error: getReplyError,
      documents: replies
    } = getCollection(`offeringComments/${props.commentId}/replies`, null, [
      "createdAt",
      "asc"
    ]);

    // mounted
    onMounted(async () => {
      console.log("Component is mounted!");
      await updateComment({
        updatedBy: user.value.uid
      });
    });

    const { error: createReplyError, addDoc: addReply } = useCollection(
      `offeringComments/${props.commentId}/replies`
    );

    const isCommenter = computed(() => {
      return offeringComment.value.commenterId == user.value.uid;
    });

    const formattedReplies = computed(() => {
      if (replies.value) {
        if (replies.value.length > 0) {
          replyDeleteCount.value = 0;
          return replies.value.map(reply => {
            let time = formatDistanceToNow(reply.createdAt.toDate());
            if (reply.requestDelete) {
              replyDeleteCount.value++;
            }
            return { ...reply, createdAt: time };
          });
        } else {
          return null;
        }
      }
    });

    const handleReply = async () => {
      try {
        isPending.value = true;
        formError.value = null;
        await addReply({
          comment: comment.value,
          commenterId: user.value.uid,
          commenterDisplayName: user.value.displayName,
          createdAt: timestamp()
        });
        isPending.value = false;
        if (createReplyError.value) {
          formError.value = createReplyError.value;
        } else {
          comment.value = "";
          console.log("ReplyCount: ", offeringComment.value.replyCount);
          await updateComment({
            updatedAt: timestamp(),
            updatedBy: user.value.uid,
            replyCount: offeringComment.value.replyCount + 1
          });
          if (useDocError.value) {
            formError.value = useDocError.value;
          }
        }
      } catch (err) {
        formError.value = "System Error: " + err.message;
      }
    };

    const handleDelete = async () => {
      formError.value = null;
      if (replies.value.length == 0) {
        isPending.value = true;
        await deleteComment();
        isPending.value = false;
        if (useDocError.value) {
          formError.value = useDocError.value;
        } else {
          router.push({ name: "OfferingComments", params: { id: props.id } });
        }
      } else {
        formError.value = "Cannot delete a comment with replies.";
      }
    };

    const handleDeleteReply = async replyId => {
      isPending.value = true;
      console.log("Delete THIS ITEM!");
      const { error: useReplyError, deleteDoc: deleteReply } = useDocument(
        `offeringComments/${props.commentId}/replies`,
        replyId
      );
      await deleteReply();
      if (useReplyError.value) {
        formError.value = useReplyError.value;
      } else {
        let replyRequestDelete = false;
        if (replyDeleteCount.value > 0) {
          replyRequestDelete = true;
        }
        await updateComment({
          replyRequestDelete: replyRequestDelete,
          updatedAt: timestamp(),
          updatedBy: user.value.uid,
          replyCount: offeringComment.value.replyCount - 1
        });
        isPending.value = false;
        if (useDocError.value) {
          formError.value = useDocError.value;
        }
      }
    };

    const handleClearRequest = async commentId => {
      const { error: useDocError, updateDoc: updateComment } = useDocument(
        "offeringComments",
        commentId
      );
      formError.value = null;
      await updateComment({
        requestDelete: false,
        requestDeleteBy: user.value.uid,
        requestedAt: timestamp()
      });
      if (useDocError.value) {
        formError.value = useDocError.value;
      } else {
        router.push({ name: "OfferingComments", params: { id: props.id } });
      }
    };

    const handleClearReplyRequest = async replyId => {
      isLoading.value = true;
      const { error: useDocError, updateDoc: updateReply } = useDocument(
        `offeringComments/${props.commentId}/replies`,
        replyId
      );
      formError.value = null;
      await updateReply({
        requestDelete: false,
        requestDeleteBy: user.value.uid,
        requestedAt: timestamp()
      });
      if (useDocError.value) {
        formError.value = useDocError.value;
      } else {
        if (replyDeleteCount.value == 0) {
          useDocError.value = null;
          await updateComment({
            replyRequestDelete: false
          });
          if (useDocError.value) {
            formError.value = useDocError.value;
            replyDeleteCount.value++;
          }
        }
      }
      isLoading.value = false;
    };

    return {
      comment,
      getReplyError,
      createReplyError,
      formError,
      isPending,
      isLoading,
      isCommenter,
      offeringComment,
      handleReply,
      handleDelete,
      handleDeleteReply,
      handleClearRequest,
      handleClearReplyRequest,
      formattedReplies,
      replyDeleteCount
    };
  }
};
</script>

<style scoped>
form {
  max-width: 100%;
  margin: 0 auto;
  padding: 30px;
  border-radius: 8px;
  box-shadow: none;
  border: transparent;
  background: white;
}
.admin-reply {
  padding: 20px;
  border-radius: 10px;
  background: white;
  margin: 16px 0;
  transition: all ease 0.2s;
  text-decoration: none;
}
.deleteRequest {
  border: solid 2px red;
}
</style>