import { ref, watchEffect } from 'vue';
import { projectFirestore } from '../firebase/config';

const getCollection = (collection, queries, order) => {

  const documents = ref(null);
  const error = ref(null);
  const isLoading = ref(true);

  // register the firestore collection reference
  let collectionRef = projectFirestore.collection(collection);

  //Handle multiple queries
  console.log(`Queries: ${queries}`);
  if (queries) {
    queries.forEach(query => {
      collectionRef = collectionRef.where(...query);
    });
  }
  console.log("Order: ", order);
  if (order) {
    // register the firestore collection reference
    collectionRef = collectionRef.orderBy(...order);
  }

  const unsub = collectionRef.onSnapshot(snap => {
    let results = [];
    snap.docs.forEach(doc => {
      // must wait for the server to create the timestamp & send it back
      doc.data().createdAt && results.push({ ...doc.data(), id: doc.id });
    });

    // update values
    documents.value = results;
    error.value = null;
    isLoading.value = false;
  }, err => {
    console.log(err.message);
    documents.value = null;
    error.value = 'could not fetch the data';
    isLoading.value = false;
  });

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub());
  });

  return { isLoading, error, documents };
};

export default getCollection;