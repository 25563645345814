<template>
  <main>
    <router-link
      :to="{ name: 'CreateTerms' }"
      class="btn btn-primary float-end"
    >
      Create Terms
    </router-link>
    <h4>Terms</h4>
    <div v-if="error" class="error">
      {{ error }}
    </div>
    <div v-if="terms">
      <div v-for="term in terms" :key="term.id">
        <router-link :to="{ name: 'TermsDetails', params: { id: term.id } }">
          <h5>{{ term.date }}</h5>
          <p>id: {{ term.id }}</p>
          <div class="single">
            <p
              class="story-text"
              v-html="term.details.substring(0, 350) + '...(more)'"
            ></p>
          </div>
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>
import getCollection from "@/composables/getCollection";

export default {
  setup() {
    const { error, documents: terms } = getCollection("rhTerms", null, [
      "createdAt",
      "desc"
    ]);
    return { error, terms };
  }
};
</script>

<style>
.single {
  display: block;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  background: white;
  margin: 16px 0;
  transition: all ease 0.2s;
  text-decoration: none;
  overflow: hidden;
}
.single:hover {
  box-shadow: 1px 2px 3px rgba(50, 50, 50, 0.05);
  transform: scale(1.02);
  transition: all ease 0.2s;
}
a {
  text-decoration: none;
}
.story-text {
  text-decoration: none;
}
</style>