import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/auth/Login.vue';

// route guard 
import { projectAuth } from '@/firebase/config';

const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser;
  console.log("requireAuth:", user);
  if (!user) {
    next({ name: 'Login' });
  } else {
    user.getIdTokenResult().then((getIdTokenResult) => {
      if (getIdTokenResult.claims.admin) {
        next();
      } else {
        projectAuth.signOut().then(() => {
          console.log("Logging out the non-admin user");
          next({ name: 'Login' });
        });
      }
    });
  }
};

const requireNoAuth = (to, from, next) => {
  let user = projectAuth.currentUser;
  console.log("requireNoAuth:", user);
  if (user) {
    // Logged in users don't need to see the Login screens
    next({ name: 'Issuers' });
  } else {
    next();
  }
};

const routes = [
  {
    path: '/issuers',
    name: 'Issuers',
    component: () => import('../views/Issuers.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/investors',
    name: 'Investors',
    component: () => import('../views/investors/Investors.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/investorDrafts',
    name: 'InvestorDrafts',
    component: () => import('../views/investors/InvestorDrafts.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/investors/create',
    name: 'CreateInvestor',
    component: () => import('../views/investors/CreateInvestor.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/offerings',
    name: 'Offerings',
    component: () => import('../views/offerings/Offerings.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/tags/:tag',
    name: 'OfferingsTag',
    component: () => import('../views/offerings/OfferingsTag.vue'),
    beforeEnter: requireAuth,
  },

  {
    path: '/offerings/create',
    name: 'CreateOffering',
    component: () => import('../views/offerings/CreateOffering.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/offering/details/:id',
    name: 'OfferingDetails',
    component: () => import('../views/offerings/OfferingDetails.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/team/:id',
    name: 'OfferingTeam',
    component: () => import('../views/offerings/team/OfferingTeam.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/team/:id/create',
    name: 'CreateTeamMember',
    component: () => import('../views/offerings/team/CreateTeamMember.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/team/:id/edit/:teamMemberId',
    name: 'EditTeamMember',
    component: () => import('../views/offerings/team/EditTeamMember.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/investments/:id',
    name: 'OfferingInvestments',
    component: () => import('../views/offerings/investments/OfferingInvestments.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/investment/:offeringId/details/:investmentId',
    name: 'InvestmentDetails',
    component: () => import('../views/offerings/investments/InvestmentDetails.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/investmentDraft/:offeringId/details/:investmentId',
    name: 'InvestmentDraftDetails',
    component: () => import('../views/offerings/investments/InvestmentDraftDetails.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/investments/:id/create',
    name: 'CreateInvestment',
    component: () => import('../views/offerings/investments/CreateInvestment.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/investment/:id/edit/:investmentId',
    name: 'EditInvestment',
    component: () => import('../views/offerings/investments/EditInvestment.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/documents/:id',
    name: 'OfferingDocuments',
    component: () => import('../views/offerings/documents/OfferingDocuments.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/documents/:id/create',
    name: 'CreateDocument',
    component: () => import('../views/offerings/documents/CreateDocument.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/documents/:id/cd',
    name: 'CreateDisclosure',
    component: () => import('../views/offerings/documents/CreateDisclosure.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/documents/:id/edit/:documentId',
    name: 'EditDocument',
    component: () => import('../views/offerings/documents/EditDocument.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/pitch/:id',
    name: 'OfferingPitch',
    component: () => import('../views/offerings/OfferingPitch.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/updates/:id',
    name: 'OfferingUpdates',
    component: () => import('../views/offerings/updates/OfferingUpdates.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/updates/:id/create',
    name: 'CreateUpdate',
    component: () => import('../views/offerings/updates/CreateUpdate.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/updates/:id/edit/:updateId',
    name: 'EditUpdate',
    component: () => import('../views/offerings/updates/EditUpdate.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/press/:id',
    name: 'OfferingPress',
    component: () => import('../views/offerings/press/OfferingPress.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/press/:id/create',
    name: 'CreatePress',
    component: () => import('../views/offerings/press/CreatePress.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/press/:id/edit/:pressId',
    name: 'EditPress',
    component: () => import('../views/offerings/press/EditPress.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/comments/:id',
    name: 'OfferingComments',
    component: () => import('../views/offerings/comments/OfferingComments.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offering/comments/:id/reply/:commentId',
    name: 'ReplyComment',
    component: () => import('../views/offerings/comments/ReplyComment.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/documents',
    name: 'Documents',
    component: () => import('../views/Documents.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/account/admins',
    name: 'Admins',
    component: () => import('@/views/account/admins/Admins.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/account/carousel',
    name: 'CarouselItems',
    component: () => import('@/views/account/carousel/CarouselItems.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/account/carousel/create',
    name: 'CreateCarouselItem',
    component: () => import('@/views/account/carousel/CreateCarouselItem.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/account/carousel/:id',
    name: 'CarouselItemDetails',
    component: () => import('@/views/account/carousel/CarouselItemDetails.vue'),
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/account/features',
    name: 'Features',
    component: () => import('@/views/account/features/Features.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/account/features/create',
    name: 'CreateFeature',
    component: () => import('@/views/account/features/CreateFeature.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/account/features/:id',
    name: 'FeatureDetails',
    component: () => import('@/views/account/features/FeatureDetails.vue'),
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/account/founders',
    name: 'Founders',
    component: () => import('@/views/account/founders/Founders.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/account/founders/create',
    name: 'CreateFounder',
    component: () => import('@/views/account/founders/CreateFounder.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/account/founders/:id',
    name: 'FounderDetails',
    component: () => import('@/views/account/founders/FounderDetails.vue'),
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/account/news',
    name: 'News',
    component: () => import('@/views/account/news/News.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/account/news/create',
    name: 'CreateNews',
    component: () => import('@/views/account/news/CreateNews.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/account/news/:id',
    name: 'NewsDetails',
    component: () => import('@/views/account/news/NewsDetails.vue'),
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/account/terms',
    name: 'Terms',
    component: () => import('@/views/account/terms/Terms.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/account/terms/create',
    name: 'CreateTerms',
    component: () => import('@/views/account/terms/CreateTerms.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/account/terms/:id',
    name: 'TermsDetails',
    component: () => import('@/views/account/terms/TermsDetails.vue'),
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/account/privacy',
    name: 'PrivacyPolicies',
    component: () => import('@/views/account/privacy/PrivacyPolicies.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/account/privacy/create',
    name: 'CreatePrivacy',
    component: () => import('@/views/account/privacy/CreatePrivacy.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/account/privacy/:id',
    name: 'PrivacyDetails',
    component: () => import('@/views/account/privacy/PrivacyDetails.vue'),
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/login',
    alias: '/',
    name: 'Login',
    component: Login,
    beforeEnter: requireNoAuth,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      console.log("No Saved Position");
      const position = {};
      position.el = to.hash;
      position.top = 80;
      if (document.querySelector(to.hash)) {
        console.log("Query Selector: to.hash:", to.hash);
        return position;
      }
    }
    if (savedPosition) {
      console.log("Saved Position! ToHash:", to.hash);
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

export default router;
