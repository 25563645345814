<template>
  <main>
    <router-link
      :to="{ name: 'CreateInvestor' }"
      class="btn btn-primary float-end"
      >Create Investor</router-link
    >
    <div class="page-content">
      <h4>Investor Drafts</h4>
      <div v-if="investmentDrafts">
        <router-link :to="{ name: 'Investors' }">Go To Investments</router-link>
        <div v-if="errorDrafts">{{ errorDrafts }}</div>
        <div
          v-for="investmentDraft in investmentDrafts"
          :key="investmentDraft.id"
        >
          <router-link
            class="offering-link"
            :to="{
              name: 'InvestmentDraftDetails',
              params: {
                offeringId: investmentDraft.offeringId,
                investmentId: investmentDraft.id
              }
            }"
          >
            <div
              class="row single "
              :class="{
                newInvestment: investmentDraft.status == 'completed',
                draftInvestment: investmentDraft.status == 'draft'
              }"
            >
              <div
                v-if="investmentDraft.investmentType == 'entity'"
                class="name col-md"
              >
                {{ investmentDraft.entityName }}
              </div>
              <div v-else class="name col-md">
                {{ investmentDraft.primaryFullName }}
              </div>
              <div class="name col-md">
                {{ investmentDraft.offeringName }}
              </div>
              <div class="name col-md">
                {{ investmentDraft.investmentType }}
              </div>
              <div class="name col-md">
                ${{ numberWithCommas(investmentDraft.totalInvestment) }}
              </div>
              <div class="name col-md">
                {{ investmentDraft.status }}
              </div>
              <div class="name col-md-1">
                {{ investmentDraft.paymentMethod }}
              </div>
              <div class="name col-md-3">
                {{ investmentDraft.createdAt.toDate().toLocaleString() }}
              </div>
            </div>
          </router-link>
        </div>
        <div
          class="text-center"
          v-if="investmentDrafts && investmentDrafts.length == 0"
        >
          <p>
            This is where the draft investments will be listed once an investor
            creates one.
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import getCollection from "@/composables/getCollection";
import usePrettyNumbers from "@/composables/usePrettyNumbers";

export default {
  setup() {
    const {
      error: errorDrafts,
      documents: investmentDrafts
    } = getCollection("investmentDrafts", null, ["createdAt", "desc"]);

    const { numberWithCommas } = usePrettyNumbers();

    return {
      investmentDrafts,
      errorDrafts,
      numberWithCommas
    };
  }
};
</script>

<style>
</style>