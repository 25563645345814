<template>
  <main>
    <form
      v-if="carouselItem"
      @submit.prevent="handleSubmit"
      class="shadow edit-form"
    >
      <div class="float-end">
        <router-link :to="{ name: 'CarouselItems' }"
          ><h4>&times;</h4></router-link
        >
      </div>
      <h4>Carousel Item Details</h4>
      <div class="form-outline mb-4">
        <label>Teaser Line</label>
        <input
          type="text"
          placeholder="Teaser"
          v-model="carouselItem.teaser"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Headline</label>
        <input
          type="text"
          placeholder="Headline"
          required
          v-model="carouselItem.headline"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Sub Heading</label>
        <textarea
          placeholder="Subheading"
          v-model="carouselItem.subheading"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Sort Order (0 not shown)</label>
        <input
          type="number"
          placeholder="Order"
          min="0"
          max="10"
          step="1"
          v-model="carouselItem.order"
          required
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="flexCheckChecked"
            v-model="carouselItem.isVideo"
          />
          <label class="form-check-label" for="flexCheckChecked">
            Video Item?
          </label>
        </div>
      </div>
      <div v-if="carouselItem.isVideo" class="form-outline mb-4">
        <label>Video Link</label>
        <input
          type="text"
          placeholder="Video Link"
          required
          v-model="carouselItem.videoURL"
          class="form-control edit-thumbnail"
        />
      </div>
      <div v-else class="form-outline mb-4">
        <div class="edit-large-picture">
          <img :src="carouselItem.imageURL" />
        </div>
        <div>
          <label>Upload Carousel Image (1620 x 550)</label>
        </div>
        <input type="file" @change="handleChange" />
      </div>
      <div class="error">{{ formError }}</div>
      <div v-if="!isPending" class="row text-center">
        <div class="col">
          <button class="btn btn-primary">Save</button>
        </div>
        <div class="col">
          <router-link
            :to="{ name: 'CarouselItems' }"
            class="btn btn-secondary"
          >
            Cancel
          </router-link>
        </div>
        <div class="col">
          <span
            class="btn btn-link text-danger"
            data-bs-toggle="modal"
            data-bs-target="#confirmDeleteModal"
          >
            Delete Item
          </span>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-primary" disabled>
          Saving...
        </button>
      </div>
    </form>
    <ModalDelete
      title="Delete this Carousel Item?"
      @confirm-delete="handleDelete"
    >
      Are you sure you want to permanently delete this carousel item?
    </ModalDelete>
  </main>
</template>

<script>
import { useRouter } from "vue-router";
import useStorage from "@/composables/useStorage";
import getDocument from "@/composables/getDocument";
import useDocument from "@/composables/useDocument";
import { timestamp } from "@/firebase/config";
import { ref } from "@vue/reactivity";
import ModalDelete from "@/components/modals/ModalDelete.vue";

export default {
  props: ["id"],
  components: { ModalDelete },
  setup(props) {
    const formError = ref(null);
    const isPending = ref(false);
    const {
      error: storageError,
      url,
      filePath,
      uploadImage,
      deleteImage
    } = useStorage();
    const { document: carouselItem } = getDocument("rhCarousels", props.id);
    const { error: docError, updateDoc, deleteDoc } = useDocument(
      "rhCarousels",
      props.id
    );
    const file = ref(null);
    const router = useRouter();

    const handleSubmit = async () => {
      try {
        let imageFilePath = "";
        let imageURL = "";
        isPending.value = true;
        formError.value = null;
        if (file.value) {
          await uploadImage(
            `rhCarousels/${carouselItem.value.id}`,
            "carouselImage",
            file.value
          );
          imageFilePath = filePath.value;
          imageURL = url.value;
        } else {
          if (carouselItem.value.imageFilePath && carouselItem.value.imageURL) {
            imageFilePath = carouselItem.value.imageFilePath;
            imageURL = carouselItem.value.imageURL;
          }
        }
        if (storageError.value) {
          formError.value = storageError.value;
          isPending.value = false;
        } else {
          await updateDoc({
            teaser: carouselItem.value.teaser,
            headline: carouselItem.value.headline,
            subheading: carouselItem.value.subheading,
            isVideo: carouselItem.value.isVideo,
            videoURL: carouselItem.value.videoURL,
            order: parseInt(carouselItem.value.order),
            imageFilePath: imageFilePath,
            imageURL: imageURL,
            editedAt: timestamp()
          });
          isPending.value = false;
          if (docError.value) {
            formError.value = docError.value;
            //TODO delete image here
          } else {
            console.log("carouselItem Added");
            router.push({
              name: "CarouselItems"
            });
          }
        }
      } catch (err) {
        formError.value = "System Error: " + err.message;
      }
      isPending.value = false;
    };

    // allowed file types
    const types = ["image/png", "image/jpeg"];

    const handleChange = e => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        formError.value = null;
      } else {
        file.value = null;
        formError.value = "Please select an image file (png or jpeg)";
      }
    };

    const handleDelete = async () => {
      console.log("Delete THIS ITEM!");
      isPending.value = true;
      formError.value = null;
      storageError.value = null;
      if (carouselItem.value.imageFilePath) {
        await deleteImage(carouselItem.value.imageFilePath);
      }
      if (storageError.value) {
        formError.value = storageError.value;
        isPending.value = false;
      } else {
        await deleteDoc();
        isPending.value = false;
        if (docError.value) {
          formError.value = docError.value;
        } else {
          router.push({ name: "CarouselItems" });
        }
      }
    };

    return {
      isPending,
      handleDelete,
      formError,
      carouselItem,
      handleSubmit,
      handleChange
    };
  }
};
</script>

<style scoped>
form {
  max-width: 100%;
  text-align: left;
}
.thumbnail {
  max-width: 100%;
  max-height: 400px;
  overflow: hidden;
  border-radius: 10px;
}
</style>