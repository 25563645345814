<template>
  <SubNav class="subnav-position" :selected="'investments'" :id="id" />
  <main>
    <form
      v-if="offering"
      @submit.prevent="handleSubmit"
      class="shadow edit-form"
    >
      <div class="float-end">
        <router-link :to="{ name: 'OfferingInvestments', params: { id: id } }"
          ><h4>&times;</h4></router-link
        >
      </div>
      <h6>Create a New Investment</h6>
      <div class="form-outline mb-4">
        <label>Investor Name</label>
        <input
          type="text"
          required
          v-model="investorName"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Investor ID</label>
        <input type="text" required v-model="investorId" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Amount</label>
        <input type="text" required v-model="amount" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>FA Status</label>
        <input type="text" required v-model="faStatus" class="form-control" />
      </div>
      <div class="error">{{ formError }}</div>
      <div v-if="!isPending" class="row text-center">
        <div class="col">
          <button class="btn btn-primary">Create</button>
        </div>
        <div class="col">
          <router-link
            :to="{ name: 'OfferingInvestments', params: { id: id } }"
            class="btn btn-secondary"
          >
            Cancel
          </router-link>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-secondary" disabled>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Creating...</span>
          </div>
        </button>
      </div>
    </form>
  </main>
</template>

<script>
import { ref } from "@vue/reactivity";
import useCollection from "@/composables/useCollection";
import getDocument from "@/composables/getDocument";
import { timestamp } from "@/firebase/config";
import { useRouter } from "vue-router";
import SubNav from "@/components/navigation/SubNav.vue";

export default {
  props: ["id"],
  components: { SubNav },
  setup(props) {
    const isPending = ref(false);
    const formError = ref(null);
    const investorName = ref("");
    const investorId = ref("");
    const amount = ref("");
    const faStatus = ref("");
    const router = useRouter();
    const { document: offering } = getDocument("offerings", props.id);

    const { error, addDoc } = useCollection("investments");

    const handleSubmit = async () => {
      try {
        isPending.value = true;
        formError.value = null;
        const ref = await addDoc({
          investorName: investorName.value,
          amount: amount.value,
          faStatus: faStatus.value,
          investorId: investorId.value,
          offeringId: props.id,
          offeringName: offering.value.name,
          issuerId: offering.value.issuerId,
          createdAt: timestamp()
        });
        isPending.value = false;
        if (error.value) {
          formError.value = error.value;
        } else {
          router.push({
            name: "EditInvestment",
            params: { id: props.id, investmentId: ref.id }
          });
        }
      } catch (err) {
        formError.value = "System Error: " + err.message;
      }
    };

    return {
      offering,
      investorName,
      amount,
      faStatus,
      investorId,
      isPending,
      formError,
      handleSubmit
    };
  }
};
</script>

<style>
</style>