<template>
  <main>
    <div
      v-if="offerings && offerings.length > 0"
      class="container page-content"
    >
      <div class="text-end">
        <router-link :to="{ name: 'CreateOffering' }" class="btn btn-primary"
          >Create Offering</router-link
        >
      </div>
      <h3 class="mt-2">Offerings</h3>
      <div class="row align-items-start">
        <div class="col-12 col-lg-9">
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-5">
            <div v-for="offering in offerings" :key="offering.id" class="col">
              <OfferingSingle :offering="offering" />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <TagCloud :offerings="offerings" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import OfferingSingle from "@/components/offerings/OfferingSingle.vue";
import TagCloud from "@/components/tags/TagCloud.vue";
import getCollection from "@/composables/getCollection";

export default {
  components: { OfferingSingle, TagCloud },
  setup() {
    const { error, documents: offerings } = getCollection(
      "offerings",
      [],
      ["name", "asc"]
    );

    return { error, offerings };
  }
};
</script>

<style scoped>
.single {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  background: white;
  margin: 16px 0;
  transition: all ease 0.2s;
  text-decoration: none;
  overflow: hidden;
}
.single:hover {
  box-shadow: 1px 2px 3px rgba(50, 50, 50, 0.05);
  transform: scale(1.02);
  transition: all ease 0.2s;
}
.thumbnail {
  max-width: 100px;
  max-height: 100px;
  overflow: hidden;
  border-radius: 10px;
}
img {
  max-width: 150%;
  max-height: 150%;
  display: block;
}
.info {
  margin: 0 30px;
}

a {
  text-decoration: none;
}

.investment {
  margin-left: auto;
}
</style>