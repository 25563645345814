<template>
  <main>
    This may be unnecessary, but could add ability to set up investor here.
  </main>
</template>

<script>
export default {};
</script>

<style>
</style>