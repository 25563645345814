<template>
  <SubNav class="subnav-position" :selected="'documents'" :id="id" />
  <main>
    <form
      v-if="offeringDocument"
      @submit.prevent="handleSave"
      class="shadow edit-form"
    >
      <div class="float-end">
        <router-link :to="{ name: 'OfferingDocuments', params: { id: id } }"
          ><h4>&times;</h4></router-link
        >
      </div>
      <div v-if="offeringDocument.disclosure">
        <h6>Edit Form C</h6>
      </div>
      <div v-else><h6>Edit the Deal Document</h6></div>
      <div class="form-outline mb-4">
        <label>Name</label>
        <input
          type="text"
          required
          v-model="offeringDocument.name"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Description</label>
        <textarea v-model="offeringDocument.description" class="form-control">
        </textarea>
      </div>
      <div class="form-outline mb-4">
        <label
          >SEC Link (https://www.sec.gov/Archives/edgar/data/file.htm)</label
        >
        <input
          type="text"
          v-model="offeringDocument.secLink"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <div v-if="offeringDocument.documentURL">
          <embed
            class="d-none d-md-block"
            :src="offeringDocument.documentURL"
            type="application/pdf"
            width="100%"
            height="600px"
          />
          <a
            class="d-md-none"
            :href="offeringDocument.documentURL"
            download="OfferingDocument"
            >Download</a
          >
        </div>
        <div v-if="offeringDocument.disclosure">
          <label>Upload Form C</label>
        </div>
        <div v-else>
          <label>Upload Deal Document</label>
        </div>
        <div class="error">{{ errorPDF }}</div>
        <div v-if="isPending">
          <input type="file" @change="handleDocument" disabled />
        </div>
        <div v-else>
          <input type="file" @change="handleDocument" />
        </div>
      </div>
      <div class="error">{{ formError }}</div>
      <div v-if="!isPending" class="row text-center">
        <div class="col">
          <button class="btn btn-primary">Save</button>
        </div>
        <div class="col">
          <router-link
            :to="{ name: 'OfferingDocuments', params: { id: id } }"
            class="btn btn-secondary"
          >
            Cancel
          </router-link>
        </div>
        <div class="col">
          <span class="btn btn-link text-danger" @click="handleDelete">
            <div v-if="offeringDocument.disclosure">
              Delete Form C
            </div>
            <div v-else>
              Delete Document
            </div>
          </span>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-secondary" disabled>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Saving...</span>
          </div>
        </button>
      </div>
    </form>
  </main>
  <Footer v-if="offeringDocument" />
</template>

<script>
import { ref } from "@vue/reactivity";
import getDocument from "@/composables/getDocument";
import useDocument from "@/composables/useDocument";
import useStorage from "@/composables/useStorage";
import SubNav from "@/components/navigation/SubNav.vue";
import { useRouter } from "vue-router";
import Footer from "@/components/navigation/Footer.vue";
import { timestamp } from "@/firebase/config";

export default {
  props: ["id", "documentId"],
  components: { SubNav, Footer },
  setup(props) {
    const isPending = ref(false);
    const formError = ref(null);
    const router = useRouter();
    const { document: offeringDocument } = getDocument(
      "offeringDocuments",
      props.documentId
    );
    const {
      error: errorOffering,
      updateDoc: updateOfferingDocument,
      deleteDoc: deleteOfferingDocument
    } = useDocument("offeringDocuments", props.documentId);
    const {
      error: errorPDF,
      url,
      filePath,
      uploadPDF,
      deleteImage: deletePDF
    } = useStorage();

    const saveDetails = async () => {
      await updateOfferingDocument({
        name: offeringDocument.value.name,
        description: offeringDocument.value.description,
        documentFilePath: offeringDocument.value.documentFilePath
          ? offeringDocument.value.documentFilePath
          : "",
        documentURL: offeringDocument.value.documentURL
          ? offeringDocument.value.documentURL
          : "",
        secLink: offeringDocument.value.secLink
          ? offeringDocument.value.secLink
          : "",
        editedAt: timestamp()
      });
    };

    const handleSave = async () => {
      try {
        isPending.value = true;
        formError.value = "";
        await saveDetails();
        isPending.value = false;
        if (errorOffering.value) {
          formError.value = errorOffering.value;
        } else {
          router.push({
            name: "OfferingDocuments",
            params: { id: props.id }
          });
        }
      } catch (err) {
        formError.value = "System Error: " + err.message;
      }
    };

    // allowed file types
    const types = ["application/pdf"];

    const handleDocument = async e => {
      isPending.value = true;
      errorPDF.value = null;
      formError.value = null;
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        await uploadPDF(
          "documents/" + props.id + "/" + props.documentId,
          "document",
          selected
        );
        if (errorPDF.value) {
          formError.value = errorPDF.value;
        } else {
          offeringDocument.value.documentFilePath = filePath.value;
          offeringDocument.value.documentURL = url.value;
          await saveDetails();
          if (errorOffering.value) {
            formError.value = errorOffering.value;
          }
        }
      } else {
        formError.value = errorPDF.value = "Please select a pdf file";
      }
      isPending.value = false;
    };

    const handleDelete = async () => {
      isPending.value = true;
      formError.value = null;
      if (offeringDocument.value.documentFilePath) {
        await deletePDF(offeringDocument.value.documentFilePath);
      }
      await deleteOfferingDocument();
      if (errorOffering.value) {
        formError.value = errorOffering.value;
      } else {
        router.push({ name: "OfferingDocuments", params: { id: props.id } });
      }
    };

    return {
      isPending,
      formError,
      errorPDF,
      handleSave,
      handleDocument,
      handleDelete,
      offeringDocument
    };
  }
};
</script>

<style>
</style>