<template>
  <SubNav class="subnav-position" :selected="'team'" :id="id" />
  <main>
    <form
      v-if="offering"
      @submit.prevent="handleSubmit"
      class="shadow edit-form"
    >
      <div class="float-end">
        <router-link :to="{ name: 'OfferingTeam', params: { id: id } }"
          ><h4>&times;</h4></router-link
        >
      </div>
      <h6>Create a New Team Member</h6>
      <div class="form-outline mb-4">
        <label>Name</label>
        <input type="text" required v-model="name" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Title</label>
        <input type="text" required v-model="title" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Email (this will be kept private)</label>
        <input
          type="text"
          required
          v-model="email"
          class="form-control"
          @blur="validateEmail"
        />
        <div class="error">{{ emailError }}</div>
      </div>
      <div class="error">{{ formError }}</div>
      <div v-if="!isPending" class="row text-center">
        <div v-if="!formError && !emailError" class="col">
          <button class="btn btn-primary">Create</button>
        </div>
        <div v-else class="col">
          <button class="btn btn-secondary" disabled>Create</button>
        </div>
        <div class="col">
          <router-link
            :to="{ name: 'OfferingTeam', params: { id: id } }"
            class="btn btn-secondary"
          >
            Cancel
          </router-link>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-secondary" disabled>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Creating...</span>
          </div>
        </button>
      </div>
    </form>
  </main>
</template>

<script>
import { ref } from "@vue/reactivity";
import useCollection from "@/composables/useCollection";
import getDocument from "@/composables/getDocument";
import { timestamp } from "@/firebase/config";
import { useRouter } from "vue-router";
import SubNav from "@/components/navigation/SubNav.vue";

export default {
  props: ["id"],
  components: { SubNav },
  setup(props) {
    const isPending = ref(false);
    const formError = ref(null);
    const name = ref("");
    const title = ref("");
    const email = ref("");
    const emailError = ref(null);
    const validEmail = ref(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    const router = useRouter();
    const { document: offering } = getDocument("offerings", props.id);

    const { error, addDoc } = useCollection("teamMembers");

    const validateEmail = () => {
      emailError.value = null;
      if (!validEmail.value.test(email.value)) {
        emailError.value = "Invalid Email Address.";
      }
    };

    const handleSubmit = async () => {
      try {
        isPending.value = true;
        formError.value = null;
        const ref = await addDoc({
          name: name.value,
          title: title.value,
          order: 0,
          offeringId: props.id,
          issuerId: offering.value.issuerId,
          createdAt: timestamp()
        });
        isPending.value = false;
        if (error.value) {
          formError.value = error.value;
        } else {
          console.log(`Ref ID: ${ref.id}`);
          const {
            error: errorContact,
            setDoc: addMemberContact
          } = useCollection(`teamMembers/${ref.id}/private`);
          await addMemberContact("contact", {
            email: email.value
          });
          if (errorContact.value) {
            formError.value = errorContact.value;
          } else {
            router.push({
              name: "EditTeamMember",
              params: { id: props.id, teamMemberId: ref.id }
            });
          }
        }
      } catch (err) {
        formError.value = "System Error: " + err.message;
      }
    };

    return {
      offering,
      name,
      title,
      email,
      emailError,
      isPending,
      formError,
      handleSubmit,
      validateEmail
    };
  }
};
</script>

<style>
</style>