  <template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="confirmInvoiceModal"
    tabindex="-1"
    aria-labelledby="confirmInvoiceModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="confirmInvoiceModalLabel">
            {{ title }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="$emit('confirm-invoice')"
          >
            Send Invoice
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: ["title"]
};
</script>
  
  <style>
</style>