<template>
  <main>
    <h4>Admins</h4>
    <h5 v-if="user">Hello {{ user.displayName }} ({{ user.email }})</h5>
    <form @submit.prevent="handleSubmit">
      <div class="form-outline mb-4">
        <label>Make an Admin by email address</label>
        <input
          type="text"
          placeholder="Email"
          v-model="email"
          class="form-control"
        />
      </div>
      <div class="error">{{ formError }}</div>
      <div class="col">
        <button class="btn btn-primary">Make Admin</button>
      </div>
    </form>
  </main>
</template>

<script>
import { ref } from "@vue/reactivity";
import { projectFunctions } from "../../../firebase/config";
import getUser from "@/composables/getUser";
export default {
  setup() {
    const { user } = getUser();
    const email = ref("");
    const formError = ref(null);
    const addAdminRole = projectFunctions.httpsCallable("addAdminRole");
    const handleSubmit = async () => {
      formError.value = null;
      console.log("Email", email.value);
      addAdminRole({ email: email.value }).then(result => {
        if (result.data.error) {
          formError.value = result.data.error;
        } else {
          formError.value = "Successfully made " + email.value + " an Admin.";
          email.value = "";
        }
      });
    };
    return { handleSubmit, email, formError, user };
  }
};
</script>

<style>
</style>