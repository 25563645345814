<template>
  <main>
    <router-link
      :to="{ name: 'CreatePrivacy' }"
      class="btn btn-primary float-end"
    >
      Create a Privacy Policy
    </router-link>
    <h4>Privacy Policies</h4>
    <div v-if="error" class="error">
      {{ error }}
    </div>
    <div v-if="privacyPolicies">
      <div v-for="privacyPolicy in privacyPolicies" :key="privacyPolicy.id">
        <router-link
          :to="{ name: 'PrivacyDetails', params: { id: privacyPolicy.id } }"
        >
          <h5>{{ privacyPolicy.date }}</h5>
          <p>id: {{ privacyPolicy.id }}</p>
          <div class="single">
            <p
              class="story-text"
              v-html="privacyPolicy.details.substring(0, 350) + '...(more)'"
            ></p>
          </div>
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>
import getCollection from "@/composables/getCollection";

export default {
  setup() {
    const { error, documents: privacyPolicies } = getCollection(
      "rhPrivacy",
      null,
      ["createdAt", "desc"]
    );
    return { error, privacyPolicies };
  }
};
</script>

<style>
.single {
  display: block;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  background: white;
  margin: 16px 0;
  transition: all ease 0.2s;
  text-decoration: none;
  overflow: hidden;
}
.single:hover {
  box-shadow: 1px 2px 3px rgba(50, 50, 50, 0.05);
  transform: scale(1.02);
  transition: all ease 0.2s;
}
a {
  text-decoration: none;
}
.story-text {
  text-decoration: none;
}
</style>