<template>
  <main>
    <div v-if="offering && investment">
      <h6>Draft Investment Review</h6>
      <div class="form-outline mb-4 container-fluid">
        <div v-if="investment.c2mError" class="error">
          {{ investment.c2mError }}
        </div>
        <div class="row">
          <div class="col-md">
            <div class="name">Investment Name</div>
            <div class="serious">{{ investment.investmentName }}</div>
          </div>
          <div class="col-md">
            <div class="name">Investment Type</div>
            <div v-if="investment.investmentType == 'joint'" class="serious">
              Joint Investment
            </div>
            <div
              v-if="investment.investmentType == 'individual'"
              class="serious"
            >
              Individual Investment
            </div>
            <div v-if="investment.investmentType == 'entity'" class="serious">
              Entity Investment
            </div>
          </div>
          <div class="col-md">
            <div v-if="investment.primaryAccreditedInvestor == 'yes'">
              <div class="name">Investor Type</div>
              <div v-if="investment.investmentType == 'joint'" class="serious">
                Accredited Investors
              </div>
              <div
                v-if="investment.investmentType == 'individual'"
                class="serious"
              >
                Accredited Investor
              </div>
              <div v-if="investment.investmentType == 'entity'" class="serious">
                Accredited Entity
              </div>
            </div>
          </div>
          <div v-if="investment.status != 'cancelled'" class="col-md">
            <div class="name">Status</div>
            <div class="serious">{{ investment.status }}</div>
          </div>
          <div v-else class="col-md">
            <div class="name">Cancelled</div>
            <div class="serious">{{ investment.cancelReason }}</div>
          </div>
        </div>
      </div>
      <div class="form-outline mb-4 container-fluid">
        <div class="row">
          <div class="col-md">
            <div class="name">Draft Created</div>
            <div class="serious">
              {{ investment.createdAt.toDate().toLocaleString() }}
            </div>
          </div>
          <div v-if="investment.completedInvestmentTime" class="col-md">
            <div class="name">Investment Time</div>
            <div class="serious">
              {{ investment.completedInvestmentTime.toDate().toLocaleString() }}
            </div>
          </div>
          <div class="col-md">
            <div class="name">C2M Offering Id</div>
            <div class="serious">{{ investment.c2mOfferingId }}</div>
          </div>
        </div>
      </div>
      <div v-if="investment.investmentType == 'entity'">
        <h6>Entity</h6>

        <div class="form-outline mb-2 container-fluid">
          <div class="row">
            <div class="col-md-3">
              <div class="name">Entity Name</div>
              <div class="serious">
                {{ investment.entityName }}
              </div>
            </div>
            <div class="col-md-3">
              <div class="name">Entity Start Date</div>
              <div class="serious">
                {{ toPrettyDate(investment.entityStartDate) }}
              </div>
            </div>

            <div class="col-md-3">
              <div class="name">Entity Type</div>
              <div class="serious">{{ investment.entityType }}</div>
            </div>
            <div class="col-md-3">
              <div class="name">Entity Industry</div>
              <div class="serious">{{ investment.entityIndustry }}</div>
            </div>
          </div>
        </div>
        <div class="form-outline mb-2 container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div v-if="investment.isFinCenRegulated" class="serious">
                This entity is FinCen Regulated
              </div>
              <div v-else class="serious">
                This entity not FinCen Regulated
              </div>
            </div>
            <div class="col-md-6">
              <div v-if="investment.isUSEntity">
                <div class="serious">This is a US Entity</div>
              </div>
              <div v-else>
                <div v-if="investment.isEntityFtin">
                  <span class="name">FTIN: </span>
                  <span class="serious">{{ investment.entityTaxId }}</span>
                </div>
                <div v-else class="serious">
                  This entity does not have an FTIN
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-outline mb-4 container-fluid">
          <div class="row">
            <div class="col-md-7">
              <div class="name">Entity Address</div>
              <div class="serious">
                <div>
                  {{ investment.entityStreetAddress1 }}
                </div>
                <div v-if="investment.entityStreetAddress2">
                  {{ investment.entityStreetAddress2 }}
                </div>
                <div>
                  {{ investment.entityCity }},
                  {{ investment.entityState }}
                  {{ investment.entityPostalCode }}
                  {{ investment.entityCountry }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <h6>Purchaser Representative / Owner Signatory</h6>
        <div class="form-outline mb-4 container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div class="serious">
                {{ investment.entityRepresentativeName }},
                {{ investment.entityRepresentativeTitle }}
              </div>
              <div class="serious">
                {{ investment.entityRepresentativePhoneNumber }}
              </div>
              <div class="serious">
                {{ investment.entityRepresentativeEmail }}
              </div>
            </div>
            <div class="col-md-6">
              <div>{{ investment.entityRepresentativeStreetAddress1 }}</div>
              <div v-if="investment.entityRepresentativeStreetAddress2">
                {{ investment.entityRepresentativeStreetAddress2 }}
              </div>
              <div>
                {{ investment.entityRepresentativeCity }}
                {{ investment.entityRepresentativeState }}
                {{ investment.entityRepresentativePostalCode }}
                {{ investment.entityRepresentativeCountry }}
              </div>
            </div>
          </div>
        </div>
        <h6>Owners (> 10% ownership)</h6>
        <div class="form-outline mb-4 container-fluid">
          <div v-if="investment.isEntityOwnersAccredited" class="serious mb-2">
            All Owners are Accredited
          </div>
          <div v-else>Owners are NOT Accredited</div>
          <div
            class="row mb-2"
            v-for="(owner, index) in investment.owners"
            :key="index"
          >
            <div class="serious fw-bold mb-2">Owner #{{ index + 1 }}</div>
            <div class="row">
              <div class="col-md-6">
                <div class="serious">{{ owner.entityOwnerName }}</div>
                <div class="serious">{{ owner.entityOwnerDateOfBirth }}</div>
                <div class="serious">{{ owner.entityOwnerEmail }}</div>
              </div>
              <div class="col-md-6">
                <div>{{ owner.entityOwnerStreetAddress1 }}</div>
                <div v-if="owner.entityOwnerStreetAddress2">
                  {{ owner.entityOwnerStreetAddress2 }}
                </div>
                <div>
                  {{ owner.entityOwnerCity }}
                  {{ owner.entityOwnerState }}
                  {{ owner.entityOwnerPostalCode }}
                  {{ owner.entityOwnerCountry }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <h6 v-if="investment.investmentType == 'joint'">Primary Investor</h6>
        <h6 v-else>Investor</h6>
        <div class="form-outline mb-2 container-fluid">
          <div class="row">
            <div class="col-md-4">
              <div class="name">Name</div>
              <div class="serious">
                {{ investment.primaryFullName }}
              </div>
            </div>
            <div class="col-md-3">
              <div class="name">Date of Birth</div>
              <div class="serious">
                {{ toPrettyDate(investment.primaryDateOfBirth) }}
              </div>
            </div>

            <div class="col-md-4">
              <div class="name">Email</div>
              <div class="serious">{{ investment.primaryEmail }}</div>
            </div>
          </div>
        </div>
        <div class="form-outline mb-4 container-fluid">
          <div class="row">
            <div class="col-md-7">
              <div class="name">Address</div>
              <div class="serious">
                <div>
                  {{ investment.primaryAddressLine1 }}
                </div>
                <div v-if="investment.primaryAddressLine2">
                  {{ investment.primaryAddressLine2 }}
                </div>
                <div>
                  {{ investment.primaryCity }},
                  {{ investment.primaryState }}
                  {{ investment.primaryPostalCode }}
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="name">Phone Number</div>
              <div class="serious">
                {{ formatPhoneNumber(investment.primaryPhoneNumber) }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="investment.investmentType == 'joint'">
          <h6>Secondary Investor</h6>
          <div class="form-outline mb-2 container-fluid">
            <div class="row">
              <div class="col-md-4">
                <div class="name">Name</div>
                <div class="serious">
                  {{ investment.secondaryFullName }}
                </div>
              </div>
              <div class="col-md-3">
                <div class="name">Date of Birth</div>
                <div class="serious">
                  {{ toPrettyDate(investment.secondaryDateOfBirth) }}
                </div>
              </div>

              <div class="col-md-4">
                <div class="name">Email</div>
                <div class="serious">{{ investment.secondaryEmail }}</div>
              </div>
            </div>
          </div>
          <div class="form-outline mb-4 container-fluid">
            <div class="row">
              <div class="col-md-7">
                <div class="name">Address</div>
                <div class="serious">
                  <div>
                    {{ investment.secondaryAddressLine1 }}
                  </div>
                  <div v-if="investment.secondaryAddressLine2">
                    {{ investment.secondaryAddressLine2 }}
                  </div>
                  <div>
                    {{ investment.secondaryCity }},
                    {{ investment.secondaryState }}
                    {{ investment.secondaryPostalCode }}
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="name">Phone Number</div>
                <div class="serious">
                  {{ formatPhoneNumber(investment.secondaryPhoneNumber) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h6>Investment Details</h6>
      <div class="form-outline mb-4 container-fluid">
        <div v-if="investment.primaryAccreditedInvestor == 'no'" class="row">
          <div class="col-md-4">
            <div v-if="investment.investmentType == 'individual'" class="name">
              Annual Income
            </div>
            <div v-else class="name">Joint Annual Income</div>
            <div class="serious">
              ${{ numberWithCommas(investment.primaryAnnualIncome) }}
            </div>
          </div>
          <div class="col-md-4">
            <div class="name">Household Net Worth</div>
            <div class="serious">
              ${{ numberWithCommas(investment.primaryNetWorth) }}
            </div>
          </div>
          <div
            v-if="investment.primaryOtherInvestments == 'yes'"
            class="col-md-4"
          >
            ${{ investment.primaryOtherInvestmentTotal }}
          </div>
        </div>
      </div>
      <div class="form-outline mb-4 container-fluid">
        <div class="row">
          <div class="col-md-4">
            <div class="name">Total Investment</div>
            <div class="serious">
              ${{ numberWithCommas(investment.totalInvestment) }}
            </div>
          </div>
          <div class="col-md-4">
            <div class="name">Total Shares</div>
            <div class="serious">
              {{ numberWithCommas(investment.totalShares) }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-outline mb-4 container-fluid">
        <div class="row">
          <div class="col-md-4">
            <div class="name">Payment Method</div>
            <div class="serious">{{ investment.paymentMethod }}</div>
          </div>
          <div v-if="investment.paymentMethod == 'ACH'" class="col-md-4">
            <div class="name">Account Type</div>
            <div class="serious">{{ investment.paymentAccountType }}</div>
          </div>
        </div>
      </div>

      <div v-if="errorOffering" class="error">{{ errorOffering }}</div>
      <div v-if="errorInvestment && !isDeleting" class="error">
        {{ errorInvestment }}
      </div>
      <div class="row">
        <div class="col-md justify-content-center p-5">
          <button class="btn btn-secondary" @click="router.go(-1)">
            <i class="bi bi-arrow-left"></i>Back to Draft Investments
          </button>
        </div>
        <div class="col-md justify-content-center p-5">
          <span
            v-if="!isDeleting"
            class="btn text-danger"
            data-bs-toggle="modal"
            data-bs-target="#confirmDeleteModal"
          >
            Delete Draft
          </span>
          <button v-else class="btn" disabled>
            Delete Draft
          </button>
        </div>
      </div>
      <div v-if="errorOffering">{{ errorOffering }}</div>
      <div v-if="errorDeleteDraft">{{ errorDeleteDraft }}</div>
    </div>
    <ModalDelete title="Delete Investment?" @confirm-delete="handleDeleteDraft">
      Are you sure you want to cancel and permanently delete this draft
      investment? This action cannot be undone.
    </ModalDelete>
  </main>
  <Footer v-if="offering" />
</template>

<script>
import ModalDelete from "@/components/modals/ModalDelete.vue";
import Footer from "@/components/navigation/Footer.vue";
import getDocument from "@/composables/getDocument";
import usePrettyNumbers from "@/composables/usePrettyNumbers";
import { useRouter } from "vue-router";
import { format } from "date-fns";
import useDocument from "@/composables/useDocument";
import { ref } from "@vue/reactivity";

export default {
  props: ["offeringId", "investmentId"],
  components: { Footer, ModalDelete },
  setup(props) {
    const isDeleting = ref(false);
    const router = useRouter();
    const {
      formatPhoneNumber,
      numberWithCommas,
      toPrettyDate
    } = usePrettyNumbers();

    const { error: errorOffering, document: offering } = getDocument(
      "offerings",
      props.offeringId
    );
    const { error: errorInvestment, document: investment } = getDocument(
      "investmentDrafts",
      props.investmentId
    );

    const { error: errorDeleteDraft, deleteDoc: deleteDraft } = useDocument(
      "investmentDrafts",
      props.investmentId
    );

    const handleDeleteDraft = async () => {
      isDeleting.value = true;
      await deleteDraft();
      if (errorDeleteDraft.value) {
        isDeleting.value = false;
        console.log(
          `Error deleting investment draft : ${errorDeleteDraft.value}`
        );
      } else {
        router.go(-1);
      }
    };

    return {
      errorOffering,
      errorInvestment,
      formatPhoneNumber,
      numberWithCommas,
      toPrettyDate,
      offering,
      investment,
      format,
      router,
      errorDeleteDraft,
      isDeleting,
      handleDeleteDraft
    };
  }
};
</script>

<style>
.btn-link {
  text-decoration: none;
}
</style>