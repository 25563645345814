<template>
  <main>
    <form @submit.prevent="handleSubmit" class="shadow edit-form">
      <div class="float-end">
        <router-link :to="{ name: 'News' }"><h4>&times;</h4></router-link>
      </div>
      <h4>Create an article</h4>
      <div class="form-outline mb-4">
        <label>Headline</label>
        <input
          type="text"
          placeholder="Headline"
          v-model="headline"
          class="form-control"
          required
        />
      </div>
      <div class="form-outline mb-4">
        <label>Story Content</label>
        <textarea
          placeholder="Story Content"
          v-model="story"
          class="form-control long-text"
          required
        />
      </div>
      <div class="form-outline mb-4">
        <label>Show on:</label>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="showOnAbout"
            v-model="showOnAboutPage"
          />
          <label class="form-check-label" for="flexCheckChecked">
            About Page
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="showOnNews"
            v-model="showOnNewsPage"
          />
          <label class="form-check-label" for="flexCheckChecked">
            Knowledge Base
          </label>
        </div>
      </div>
      <div class="form-outline mb-4">
        <label>Sort Order (0 not shown)</label>
        <input
          type="number"
          min="0"
          max="100"
          step="1"
          v-model="order"
          class="form-control"
          required
        />
      </div>
      <div class="form-outline mb-4">
        <div>
          <label>Upload Story Image (200 x 200)</label>
        </div>
        <input type="file" @change="handleChange" />
      </div>
      <div class="error">{{ formError }}</div>
      <div v-if="!isPending">
        <router-link
          :to="{ name: 'News' }"
          class="btn btn-secondary btn-block float-end"
        >
          Cancel
        </router-link>
        <button class="btn btn-primary btn-block">
          Create
        </button>
      </div>
      <div v-else>
        <button class="btn btn-primary" disabled>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Saving...</span>
          </div>
        </button>
      </div>
    </form>
  </main>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import useStorage from "@/composables/useStorage";
import useCollection from "@/composables/useCollection";
import useDocument from "@/composables/useDocument";
import getUser from "@/composables/getUser";
import { timestamp } from "@/firebase/config";

export default {
  setup() {
    const isPending = ref(false);
    const formError = ref(null);
    const headline = ref("");
    const story = ref("");
    const order = ref(0);
    const file = ref(null);
    const showOnAboutPage = ref(false);
    const showOnNewsPage = ref(false);

    const { error: docError, addDoc } = useCollection("rhNews");
    const {
      error: storageError,
      filePath,
      url,
      filePathMedium,
      urlMedium,
      filePathThumb,
      urlThumb,
      uploadImageResize
    } = useStorage();
    const { user } = getUser();
    const router = useRouter();

    // allowed file types
    const types = ["image/png", "image/jpeg"];

    const handleChange = e => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        formError.value = null;
      } else {
        file.value = null;
        formError.value = "Please select an image file (png or jpeg)";
      }
    };

    const handleSubmit = async () => {
      try {
        isPending.value = true;
        formError.value = null;
        storageError.value = null;
        const res = await addDoc({
          headline: headline.value,
          story: story.value,
          order: parseInt(order.value),
          showOnAboutPage: showOnAboutPage.value,
          showOnNewsPage: showOnNewsPage.value,
          createdBy: user.value.uid,
          createdAt: timestamp()
        });
        if (docError.value) {
          formError.value = docError.value;
        } else {
          if (file.value) {
            await uploadImageResize(
              `rhNews/${res.id}`,
              "newsImage",
              file.value
            );
            if (!storageError.value && filePath.value && url.value) {
              const { updateDoc } = useDocument("rhNews", res.id);
              await updateDoc({
                imageFilePath: filePath.value,
                imageURL: url.value,
                imageFilePathMedium: filePathMedium.value,
                imageURLMedium: urlMedium.value,
                imageFilePathThumb: filePathThumb.value,
                imageURLThumb: urlThumb.value,
                editedAt: timestamp()
              });
              if (docError.value) {
                formError.value = docError.value;
              } else {
                router.push({
                  name: "News"
                });
              }
            } else {
              formError.value = "Error uploading Image " + storageError.value;
            }
          }
        }
        router.push({
          name: "News"
        });
        isPending.value = false;
      } catch (err) {
        formError.value = "System Error: " + err.message;
      }
    };

    return {
      headline,
      story,
      order,
      showOnAboutPage,
      showOnNewsPage,
      isPending,
      formError,
      handleSubmit,
      handleChange
    };
  }
};
</script>

<style scoped>
form {
  max-width: 100%;
  text-align: left;
}
</style>