<template>
  <SubNav class="subnav-position" :selected="'documents'" :id="id" />
  <main>
    <div v-if="offeringDocuments" class="subnav-detail">
      <div class="row text-center">
        <div class="col">
          <router-link
            class="btn btn-primary"
            :to="{ name: 'CreateDocument', params: { id: id } }"
          >
            Upload Deal Documents
          </router-link>
        </div>
        <div class="col">
          <router-link
            class="btn btn-primary"
            :to="{ name: 'CreateDisclosure', params: { id: id } }"
          >
            Upload Form C
          </router-link>
        </div>
      </div>
      <div v-if="error">{{ error }}</div>
      <div
        v-for="offeringDocument in offeringDocuments"
        :key="offeringDocument.id"
      >
        <router-link
          :to="{
            name: 'EditDocument',
            params: { id: id, documentId: offeringDocument.id }
          }"
        >
          <div class="row single">
            <div class="col">
              <div class="subnav-thumbnail">
                <img src="@/assets/img/pdf.png" />
              </div>
            </div>
            <div class="col name">
              {{ offeringDocument.name }}
            </div>
            <div class="col message">
              {{ offeringDocument.description }}
            </div>
            <div v-if="offeringDocument.disclosure" class="col name">
              Disclosure Document
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </main>
  <Footer v-if="offeringDocuments" />
</template>

<script>
import SubNav from "@/components/navigation/SubNav.vue";
import getCollection from "@/composables/getCollection";
import { ref } from "@vue/reactivity";
import Footer from "@/components/navigation/Footer.vue";

export default {
  props: ["id"],
  components: { SubNav, Footer },
  setup(props) {
    const isPending = ref(false);
    const { error, documents: offeringDocuments } = getCollection(
      "offeringDocuments",
      [["offeringId", "==", props.id]],
      ["createdAt", "desc"]
    );

    return {
      isPending,
      error,
      offeringDocuments
    };
  }
};
</script>

<style>
</style>