<template>
  <main>
    <form @submit.prevent="handleSubmit" class="shadow edit-form">
      <div class="float-end">
        <router-link :to="{ name: 'PrivacyPolicies' }"
          ><h4>&times;</h4></router-link
        >
      </div>
      <h4>Create a New Privacy Policy</h4>
      <div class="form-outline mb-4">
        <label>Details</label>
        <textarea
          placeholder="Privacy Policy"
          v-model="details"
          class="form-control long-text"
          required
        />
      </div>
      <h5>Preview</h5>
      <p class="story-text border p-2" v-html="details"></p>
      <div class="error">{{ formError }}</div>
      <div v-if="!isPending">
        <router-link
          :to="{ name: 'PrivacyPolicies' }"
          class="btn btn-secondary btn-block float-end"
        >
          Cancel
        </router-link>
        <button class="btn btn-primary btn-block">
          Create
        </button>
      </div>
      <div v-else>
        <button class="bt btn-primary btn-block" disabled>
          Creating...
        </button>
      </div>
    </form>
  </main>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import useCollection from "@/composables/useCollection";
import getUser from "@/composables/getUser";
import { timestamp } from "@/firebase/config";
import { format } from "date-fns";

export default {
  setup() {
    const isPending = ref(false);
    const formError = ref(null);
    const details = ref("");
    const { error: docError, addDoc } = useCollection("rhPrivacy");
    const { user } = getUser();
    const router = useRouter();

    const handleSubmit = async () => {
      try {
        isPending.value = true;
        formError.value = null;
        await addDoc({
          details: details.value,
          date: format(new Date(), "MMM d, uuuu HH:mm:ss"),
          createdBy: user.value.uid,
          createdAt: timestamp()
        });
        if (docError.value) {
          formError.value = docError.value;
        } else {
          router.push({
            name: "PrivacyPolicies"
          });
        }
        isPending.value = false;
      } catch (err) {
        formError.value = "System Error: " + err.message;
      }
    };

    return {
      details,
      isPending,
      formError,
      handleSubmit
    };
  }
};
</script>

<style scoped>
form {
  max-width: 100%;
  text-align: left;
}

.story-text {
  overflow-y: auto;
  max-height: 300px;
  text-decoration: none;
}
</style>