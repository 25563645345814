<template>
  <main>
    <div v-if="offering && investment">
      <h6>Investment Review</h6>
      <div class="form-outline mb-4 container-fluid">
        <div v-if="investment.c2mError" class="error">
          C2M Error: {{ investment.c2mError }}
        </div>
        <div class="row">
          <div class="col-md">
            <div class="name">Investment Name</div>
            <div class="serious">{{ investment.investmentName }}</div>
          </div>
          <div class="col-md">
            <div class="name">Investment Type</div>
            <div v-if="investment.investmentType == 'joint'" class="serious">
              Joint Investment
            </div>
            <div v-if="investment.investmentType == 'individual'" class="serious">
              Individual Investment
            </div>
            <div v-if="investment.investmentType == 'entity'" class="serious">
              Entity Investment
            </div>
          </div>
          <div class="col-md">
            <div v-if="investment.primaryAccreditedInvestor == 'yes'">
              <div class="name">Investor Type</div>
              <div v-if="investment.investmentType == 'joint'" class="serious">
                Accredited Investors
              </div>
              <div v-if="investment.investmentType == 'individual'" class="serious">
                Accredited Investor
              </div>
              <div v-if="investment.investmentType == 'entity'" class="serious">
                Accredited Entity
              </div>
            </div>
          </div>
          <div v-if="investment.status != 'cancelled'" class="col-md">
            <div class="name">Status</div>
            <div class="serious">{{ investment.status }}</div>
          </div>
          <div v-else class="col-md">
            <div class="name">Cancelled</div>
            <div class="serious">{{ investment.cancelReason }}</div>
          </div>
        </div>
      </div>
      <div class="form-outline mb-4 container-fluid">
        <div class="row">
          <div class="col-md">
            <div class="name">Offering Name</div>
            <div class="serious">{{ investment.offeringName }}</div>
          </div>
          <div class="col-md">
            <div class="name">Investment ID</div>
            <div class="serious">{{ investment.id }}</div>
          </div>
          <div class="col-md">
            <div class="name">Signature Date</div>
            <div class="serious">{{ investment.zsSignatureTime }}</div>
          </div>
        </div>
      </div>
      <div class="form-outline mb-4 container-fluid">
        <div class="row">
          <div class="col-md">
            <div class="name">C2M Investment ID</div>
            <div class="serious">{{ investment.c2mInvestmentId }}</div>
          </div>
          <div class="col-md">
            <div class="name">C2M Investor Id</div>
            <div class="serious">{{ investment.c2mInvestorId }}</div>
          </div>
          <div class="col-md">
            <div class="name">C2M Offering Id</div>
            <div class="serious">{{ investment.c2mOfferingId }}</div>
          </div>
        </div>
      </div>
      <div v-if="investment.investmentType == 'entity'">
        <h6>Entity</h6>

        <div class="form-outline mb-2 container-fluid">
          <div class="row">
            <div class="col-md-3">
              <div class="name">Entity Name</div>
              <div class="serious">
                {{ investment.entityName }}
              </div>
            </div>
            <div class="col-md-3">
              <div class="name">Entity Start Date</div>
              <div class="serious">
                {{ toPrettyDate(investment.entityStartDate) }}
              </div>
            </div>

            <div class="col-md-3">
              <div class="name">Entity Type</div>
              <div class="serious">{{ investment.entityType }}</div>
            </div>
            <div class="col-md-3">
              <div class="name">Entity Industry</div>
              <div class="serious">{{ investment.entityIndustry }}</div>
            </div>
          </div>
        </div>
        <div class="form-outline mb-2 container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div v-if="investment.isFinCenRegulated" class="serious">
                This entity is FinCen Regulated
              </div>
              <div v-else class="serious">
                This entity not FinCen Regulated
              </div>
            </div>
            <div class="col-md-6">
              <div v-if="investment.isUSEntity">
                <div class="serious">This is a US Entity</div>
              </div>
              <div v-else>
                <div v-if="investment.isEntityFtin">
                  <span class="name">FTIN: </span>
                  <span class="serious">{{ investment.entityTaxId }}</span>
                </div>
                <div v-else class="serious">
                  This entity does not have an FTIN
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-outline mb-4 container-fluid">
          <div class="row">
            <div class="col-md-7">
              <div class="name">Entity Address</div>
              <div class="serious">
                <div>
                  {{ investment.entityStreetAddress1 }}
                </div>
                <div v-if="investment.entityStreetAddress2">
                  {{ investment.entityStreetAddress2 }}
                </div>
                <div>
                  {{ investment.entityCity }},
                  {{ investment.entityState }}
                  {{ investment.entityPostalCode }}
                  {{ investment.entityCountry }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <h6>Purchaser Representative / Owner Signatory</h6>
        <div class="form-outline mb-4 container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div class="serious">
                {{ investment.entityRepresentativeName }},
                {{ investment.entityRepresentativeTitle }}
              </div>
              <div class="serious">
                {{ investment.entityRepresentativePhoneNumber }}
              </div>
              <div class="serious">
                {{ investment.entityRepresentativeEmail }}
              </div>
            </div>
            <div class="col-md-6">
              <div>{{ investment.entityRepresentativeStreetAddress1 }}</div>
              <div v-if="investment.entityRepresentativeStreetAddress2">
                {{ investment.entityRepresentativeStreetAddress2 }}
              </div>
              <div>
                {{ investment.entityRepresentativeCity }}
                {{ investment.entityRepresentativeState }}
                {{ investment.entityRepresentativePostalCode }}
                {{ investment.entityRepresentativeCountry }}
              </div>
            </div>
          </div>
        </div>
        <h6>Owners (> 10% ownership)</h6>
        <div class="form-outline mb-4 container-fluid">
          <div v-if="investment.isEntityOwnersAccredited" class="serious mb-2">
            All Owners are Accredited
          </div>
          <div v-else>Owners are NOT Accredited</div>
          <div class="row mb-2" v-for="(owner, index) in investment.owners" :key="index">
            <div class="serious fw-bold mb-2">Owner #{{ index + 1 }}</div>
            <div class="row">
              <div class="col-md-6">
                <div class="serious">{{ owner.entityOwnerName }}</div>
                <div class="serious">{{ owner.entityOwnerDateOfBirth }}</div>
                <div class="serious">{{ owner.entityOwnerEmail }}</div>
              </div>
              <div class="col-md-6">
                <div>{{ owner.entityOwnerStreetAddress1 }}</div>
                <div v-if="owner.entityOwnerStreetAddress2">
                  {{ owner.entityOwnerStreetAddress2 }}
                </div>
                <div>
                  {{ owner.entityOwnerCity }}
                  {{ owner.entityOwnerState }}
                  {{ owner.entityOwnerPostalCode }}
                  {{ owner.entityOwnerCountry }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <h6 v-if="investment.investmentType == 'joint'">Primary Investor</h6>
        <h6 v-else>Investor</h6>
        <div class="form-outline mb-2 container-fluid">
          <div class="row">
            <div class="col-md-4">
              <div class="name">Name</div>
              <div class="serious">
                {{ investment.primaryFullName }}
              </div>
            </div>
            <div class="col-md-3">
              <div class="name">Date of Birth</div>
              <div class="serious">
                {{ toPrettyDate(investment.primaryDateOfBirth) }}
              </div>
            </div>

            <div class="col-md-4">
              <div class="name">Email</div>
              <div class="serious">{{ investment.primaryEmail }}</div>
            </div>
          </div>
        </div>
        <div class="form-outline mb-4 container-fluid">
          <div class="row">
            <div class="col-md-7">
              <div class="name">Address</div>
              <div class="serious">
                <div>
                  {{ investment.primaryAddressLine1 }}
                </div>
                <div v-if="investment.primaryAddressLine2">
                  {{ investment.primaryAddressLine2 }}
                </div>
                <div>
                  {{ investment.primaryCity }},
                  {{ investment.primaryState }}
                  {{ investment.primaryPostalCode }}
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="name">Phone Number</div>
              <div class="serious">
                {{ formatPhoneNumber(investment.primaryPhoneNumber) }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="investment.investmentType == 'joint'">
          <h6>Secondary Investor</h6>
          <div class="form-outline mb-2 container-fluid">
            <div class="row">
              <div class="col-md-4">
                <div class="name">Name</div>
                <div class="serious">
                  {{ investment.secondaryFullName }}
                </div>
              </div>
              <div class="col-md-3">
                <div class="name">Date of Birth</div>
                <div class="serious">
                  {{ toPrettyDate(investment.secondaryDateOfBirth) }}
                </div>
              </div>

              <div class="col-md-4">
                <div class="name">Email</div>
                <div class="serious">{{ investment.secondaryEmail }}</div>
              </div>
            </div>
          </div>
          <div class="form-outline mb-4 container-fluid">
            <div class="row">
              <div class="col-md-7">
                <div class="name">Address</div>
                <div class="serious">
                  <div>
                    {{ investment.secondaryAddressLine1 }}
                  </div>
                  <div v-if="investment.secondaryAddressLine2">
                    {{ investment.secondaryAddressLine2 }}
                  </div>
                  <div>
                    {{ investment.secondaryCity }},
                    {{ investment.secondaryState }}
                    {{ investment.secondaryPostalCode }}
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="name">Phone Number</div>
                <div class="serious">
                  {{ formatPhoneNumber(investment.secondaryPhoneNumber) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h6>Investment Details</h6>
      <div class="form-outline mb-4 container-fluid">
        <div v-if="investment.primaryAccreditedInvestor == 'no'" class="row">
          <div class="col-md-4">
            <div v-if="investment.investmentType == 'individual'" class="name">
              Annual Income
            </div>
            <div v-else class="name">Joint Annual Income</div>
            <div class="serious">
              ${{ numberWithCommas(investment.primaryAnnualIncome) }}
            </div>
          </div>
          <div class="col-md-4">
            <div class="name">Household Net Worth</div>
            <div class="serious">
              ${{ numberWithCommas(investment.primaryNetWorth) }}
            </div>
          </div>
          <div v-if="investment.primaryOtherInvestments == 'yes'" class="col-md-4">
            ${{ investment.primaryOtherInvestmentTotal }}
          </div>
        </div>
      </div>
      <div class="form-outline mb-4 container-fluid">
        <div class="row">
          <div class="col-md-4">
            <div class="name">Total Investment</div>
            <div class="serious">
              ${{ numberWithCommas(investment.totalInvestment) }}
            </div>
          </div>
          <div class="col-md-4">
            <div class="name">Total Shares</div>
            <div class="serious">
              {{ numberWithCommas(investment.totalShares) }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-outline mb-4 container-fluid">
        <div class="row">
          <div class="col-md-4">
            <div class="name">Payment Method</div>
            <div class="serious">{{ investment.paymentMethod }}</div>
          </div>
          <div v-if="investment.paymentMethod == 'ACH'" class="col-md-4">
            <div class="name">Account Type</div>
            <div class="serious">{{ investment.paymentAccountType }}</div>
          </div>
        </div>
      </div>
      <h6>Subscription agreement for the {{ offering.name }} offering</h6>
      <div class="row single" @click="handleDocumentLoad">
        <div class="col-md-2">
          <span class="btn-link">
            <div class="subnav-thumbnail">
              <img src="@/assets/img/pdf.png" />
            </div>
          </span>
        </div>
        <div class="col-md">
          The agreement was signed {{ investment.zsSignatureTime }}
        </div>
        <div class="col-md">
          <div v-if="errorDocument" class="error">
            {{ errorDocument }}
          </div>
          <div v-else>
            <span v-if="documentShowing">Hide</span>
            <span v-else>Show</span>
          </div>
        </div>
      </div>
      <div v-if="downloadURL && documentShowing">
        <embed class="d-none d-md-block" :src="downloadURL" type="application/pdf" width="100%" height="600px" />
        <a class="d-md-none" :href="downloadURL" download="SubscriptionDoc">Download</a>
      </div>
      <div v-if="account" class="error">{{ errorAccount }}</div>
      <div class="error">{{ errorOffering }}</div>
      <div class="error">{{ errorUpdate }}</div>
      <div class="error">{{ errorInvestmentAccount }}</div>
      <div v-if="errorOffering" class="error">{{ errorOffering }}</div>
      <div v-if="errorInvestment" class="error">{{ errorInvestment }}</div>
      <div v-if="errorCancel" class="error">{{ errorCancel }}</div>
      <div class="row">
        <div class="col-md justify-content-center p-5">
          <button class="btn btn-secondary" @click="router.go(-1)">
            <i class="bi bi-arrow-left"></i>Back to Investments
          </button>
        </div>
        <div v-if="investment.status != 'voided' && investment.status != 'invested' &&
          (investment.status == 'C2MError' ||
            investment.status == 'cancelled' ||
            isCancellable(offering.offeringCloses)) &&
          !cancelPending.includes(investment.id)
          " class="col-md p-5 border border-danger">
          <div class="row">Reason for Cancellation</div>
          <div class="row pb-2">
            <input type="text" required v-model="investment.cancelReason" class="form-control" />
          </div>
          <div>
            <div class="row" v-if="investment.status != 'cancelled' &&
              investment.status != 'voided'
              ">
              <span v-if="investment.cancelReason" class="btn btn-danger" data-bs-toggle="modal"
                data-bs-target="#confirmDeleteModal">
                <div>
                  Cancel Investment
                </div>
              </span>
              <button v-else class="btn btn-danger" disabled>
                Cancel Investment
              </button>
            </div>
            <div class="row" v-else>
              <button v-if="investment.cancelReason && !isInvesting" class="btn btn-danger"
                @click="handleUpdateCancelReason">
                Update Reason
              </button>
              <button v-else class="btn btn-danger" disabled>
                Update Reason
              </button>
            </div>
          </div>
        </div>
        <div v-if="investment.status == 'C2MError'" class="col-md justify-content-center p-5">
          <button v-if="!isInvesting" class="btn btn-primary" @click="handleConfirmInvestment">
            Resubmit Investment
          </button>
          <button v-else class="btn btn-secondary" disabled>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Saving...</span>
            </div>
          </button>
        </div>
        <div v-if="investment.status == 'cancelled'" class="col-md justify-content-center p-5">
          <span v-if="!isInvesting" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#confirmVoidModal">
            <div>Void Investment</div>
          </span>
          <button v-else class="btn btn-secondary" disabled>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Saving...</span>
            </div>
          </button>
        </div>
        <div v-if="investment.status == createdStatus" class="col-md justify-content-center p-5">
          <button v-if="!isInvesting" class="btn btn-primary" @click="handleAcceptInvestment(acceptedStatus)">
            Accept Investment
          </button>
          <button v-else class="btn btn-secondary" disabled>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Saving...</span>
            </div>
          </button>
        </div>
        <div v-if="investment.status == acceptedStatus" class="col-md justify-content-center p-5">
          <div v-if="offering.interestRate != investment.interestRate" class="row"><span class="error">Interest Rates
              don't match, can't close investment.</span></div>
          <div v-else class="row">
            <button v-if="!isInvesting" class="btn btn-primary" data-bs-toggle="modal"
              data-bs-target="#confirmCloseModal">
              Close Investment
            </button>
            <button v-else class="btn btn-secondary" disabled>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Saving...</span>
              </div>
            </button>
          </div>
          <div class="row p-5">
            <button v-if="!isInvesting" class="btn btn-secondary" @click="handleSendTest()">
              Send Test Confirmation Email
            </button>
          </div>
        </div>
        <div v-if="investment.status == investedStatus" class="col-md justify-content-center p-5">
          This investment has been completed. The funds have moved out of escrow and the users was notified via email on
          {{
            investment.completedAt.toDate().toString() }}.
        </div>
      </div>
    </div>
    <ModalVoid title="Void Investment?" @confirm-void="handleVoidedInvestment">
      Are you sure you want to void and permanently delete this investment? This
      action cannot be undone and the account numbers will be removed.
      <div v-if="account">
        <div v-if="account.paymentAccountNumber">
          <div>Account Type: {{ investment.paymentAccountType }}</div>
          <div>Account Holder: {{ investment.paymentAccountHolderName }}</div>
          <div>Routing Number: {{ account.paymentAccountRoutingNumber }}</div>
          <div>Account Number: {{ account.paymentAccountNumber }}</div>
        </div>
      </div>
    </ModalVoid>
    <ModalDelete title="Cancel Investment?" @confirm-delete="handleCancelInvestment">
      Are you sure you want to cancel and permanently delete this investment?
      This action cannot be undone.
    </ModalDelete>
    <ModalClose title="Close Investment?" @confirm-close="handleCloseInvestment">
      Are you ready to move this investment from escrow into the offering? This will send a FINRA required email to the
      investor and cannot be undone.
    </ModalClose>
  </main>
  <Footer v-if="offering" />
</template>

<script>
import ModalDelete from "@/components/modals/ModalDelete.vue";
import ModalVoid from "@/components/modals/ModalVoid.vue";
import ModalClose from "@/components/modals/ModalClose.vue";
import Footer from "@/components/navigation/Footer.vue";
import getDocument from "@/composables/getDocument";
import useCollection from "@/composables/useCollection";
import usePrettyNumbers from "@/composables/usePrettyNumbers";
import { useRouter } from "vue-router";
import { format } from "date-fns";
import { projectFunctions } from "@/firebase/config";
import { ref } from "@vue/reactivity";
import useDocument from "@/composables/useDocument";
import { timestamp } from "@/firebase/config";
import useStorage from "@/composables/useStorage";

export default {
  props: ["offeringId", "investmentId"],
  components: { Footer, ModalDelete, ModalVoid, ModalClose },
  setup(props) {
    const acceptedStatus = ref("accepted");
    const createdStatus = ref("created");
    const investedStatus = ref("invested");
    const documentShowing = ref(false);
    const downloadURL = ref("");
    const cancelPending = ref([]);
    const today = new Date();
    const isInvesting = ref(false);
    const errorCancel = ref(null);

    const router = useRouter();

    const { error: errorDocument, getSubscriptionDocumentURL } = useStorage();

    const {
      formatPhoneNumber,
      numberWithCommas,
      toPrettyDate
    } = usePrettyNumbers();

    const cancelInvestment = projectFunctions.httpsCallable("cancelInvestment");

    const closeInvestment = projectFunctions.httpsCallable("closeInvestment");

    const { error: errorOffering, document: offering } = getDocument(
      "offerings",
      props.offeringId
    );
    const { error: errorInvestment, document: investment } = getDocument(
      "investments",
      props.investmentId
    );
    const { error: errorAccount, document: account } = getDocument(
      `investments/${props.investmentId}/private`,
      "account"
    );
    const {
      error: errorInvestmentAccount,
      setDoc: addInvestmentAccount
    } = useCollection(`investments/${props.investmentId}/private`);

    const resubmitInvestment = projectFunctions.httpsCallable(
      "resubmitInvestment"
    );

    const { error: errorUpdate, updateDoc: updateInvestment } = useDocument(
      "investments",
      props.investmentId
    );

    const isCancellable = closingDate => {
      if (closingDate) {
        const closingParse = closingDate.split("-");
        const closeDate = new Date(
          closingParse[0],
          closingParse[1] - 1,
          closingParse[2]
        );
        closeDate.setHours(23, 59, 59, 0);
        const timeMilSec = closeDate.getTime() - today.getTime();
        return timeMilSec / (1000 * 60 * 60 * 24) >= 2;
      } else {
        return false;
      }
    };

    const handleDocumentLoad = async () => {
      if (!downloadURL.value) {
        downloadURL.value = await getSubscriptionDocumentURL(
          investment.value.investorId,
          investment.value.id
        );
      }
      documentShowing.value = !documentShowing.value;
    };

    const handleUpdateCancelReason = async () => {
      isInvesting.value = true;
      try {
        await updateInvestment({
          cancelReason: investment.value.cancelReason,
          editedAt: timestamp(),
          editedBy: "Admin"
        });
        isInvesting.value = false;
      } catch (err) {
        errorCancel.value = `Error: ${err.message}`;
      }
    };

    const handleCancelInvestment = async () => {
      try {
        isInvesting.value = true;
        cancelPending.value.push(investment.value.id);
        errorCancel.value = null;
        const cancelInvestmentResult = await cancelInvestment({
          offeringId: investment.value.offeringId,
          totalInvestment: investment.value.totalInvestment,
          investmentId: investment.value.id,
          owners: investment.value.owners,
          cancelReason: investment.value.cancelReason
        });
        if (cancelInvestmentResult.data.error) {
          errorCancel.value = cancelInvestmentResult.data.error;
        }
        cancelPending.value.pop(investment.value.id);
        isInvesting.value = false;
      } catch (err) {
        errorCancel.value = `Error: ${err.message}`;
        cancelPending.value.pop(investment.value.id);
      }
    };

    const handleVoidedInvestment = async () => {
      try {
        isInvesting.value = true;
        await addInvestmentAccount("account", {
          primarySSN: "",
          secondarySSN: "",
          owners: "",
          paymentAccountNumber: "",
          paymentAccountRoutingNumber: ""
        });
        isInvesting.value = false;
        if (!errorInvestmentAccount.value) {
          await updateInvestment({
            status: "voided",
            primarySSN: "*********",
            secondarySSN: "*********",
            owners: "",
            paymentAccountNumber: "*********",
            paymentAccountNumberConfirmed: "",
            paymentAccountRoutingNumber: "*********",
            editedAt: timestamp(),
            editedBy: "Admin"
          });
        }
      } catch (err) {
        console.log(`Server Error: ${err.message}`);
        isInvesting.value = false;
      }
    };

    const handleSendTest = async () => {
      try {
        // This is a test function of handleCloseInvestment(), will build the proper email but send to bitfog, namewarden, and roundhere
        isInvesting.value = true;
        console.log(`Close Investment investorFullName`);
        // Need to handle Joint and Entity Investment
        let investorRepresentativeName = '';
        if (investment.value.investmentType == 'entity') {
          investorRepresentativeName = investment.value.entityRepresentativeName + ", " + investment.value.entityRepresentativeTitle + ", " + investment.value.entityName;
        } else if (investment.value.investmentType == 'joint') {
          investorRepresentativeName = investment.value.primaryFullName + ' and ' + investment.value.secondaryFullName;
        } else {
          investorRepresentativeName = investment.value.primaryFullName;
        }

        let maturityDate = '';
        let totalInterest = 0;
        let totalReturn = 0;
        if (offering.value.offeringType == 'debt') {
          maturityDate = toPrettyDate(offering.value.maturityDate);
          totalInterest = (investment.value.totalInvestment * 2 * parseFloat(investment.value.interestRate) / 100).toFixed(2);
          totalReturn = (investment.value.totalInvestment + parseFloat(totalInterest)).toFixed(2);
          console.log(`Total Investment: ${investment.value.totalInvestment} Total Interest: ${totalInterest}  Total Return: ${numberWithCommas(totalReturn)}`);
        }

        const closeInvestmentResult = await closeInvestment({
          isTestingCall: true,
          investmentId: investment.value.id,
          investmentName: investment.value.investmentName,
          investorEmail: 'michael+investor@bitfog.com',
          investorFullName: investorRepresentativeName,
          closeDate: offering.value.closeDate,
          investmentDescription: offering.value.investmentDescription,
          returnDescription: offering.value.returnDescription,
          offeringTypeDescription: offering.value.offeringTypeDescription,
          issuerName: offering.value.issuerName,
          offeringType: offering.value.offeringType,  //debt or equity
          interestRate: offering.value.interestRate,
          maturityDate: maturityDate,
          totalInvestment: numberWithCommas(investment.value.totalInvestment),
          totalInterest: totalInterest,
          totalReturn: numberWithCommas(totalReturn),
          amountRaised: numberWithCommas(offering.value.amountRaised),
          pricePerShare: offering.value.pricePerShare,
          issuerCommonName: offering.value.issuerCommonName,
          spvName: offering.value.spvName,
          initialFee: offering.value.initialFee,
          successFeePercent: offering.value.successFeePercent,
          successFeeAmount: offering.value.successFeeAmount,
          issuerContactName: offering.value.contactName,
          issuerContactEmail: 'michael+issuer@namewarden.net',
          escrowDate: offering.value.escrowDate,
          maturityYears: offering.value.maturityYears,
          closeDateDescription: offering.value.closeDateDescription
        });
        if (closeInvestmentResult.data.error) {
          errorCancel.value = closeInvestmentResult.data.error;
        }
        isInvesting.value = false;
      } catch (err) {
        errorCancel.value = `Error: ${err.message}`;
      }
    };

    const handleCloseInvestment = async () => {
      try {
        isInvesting.value = true;
        console.log(`Close Investment investorFullName`);
        // Need to handle Joint and Entity Investment
        let investorRepresentativeName = '';
        let investorRepresentativeEmail = '';
        if (investment.value.investmentType == 'entity') {
          investorRepresentativeName = investment.value.entityRepresentativeName + ", " + investment.value.entityRepresentativeTitle + ", " + investment.value.entityName;
          investorRepresentativeEmail = investment.value.entityRepresentativeEmail;
        } else if (investment.value.investmentType == 'joint') {
          investorRepresentativeName = investment.value.primaryFullName + ' and ' + investment.value.secondaryFullName;
          investorRepresentativeEmail = investment.value.primaryEmail;
        } else {
          investorRepresentativeName = investment.value.primaryFullName;
          investorRepresentativeEmail = investment.value.primaryEmail;
        }

        let maturityDate = '';
        let totalInterest = 0;
        let totalReturn = 0;
        if (offering.value.offeringType == 'debt') {
          maturityDate = toPrettyDate(offering.value.maturityDate);
          totalInterest = (investment.value.totalInvestment * 2 * parseFloat(investment.value.interestRate) / 100).toFixed(2);
          totalReturn = (investment.value.totalInvestment + parseFloat(totalInterest)).toFixed(2);
          console.log(`Total Interest: ${totalInterest}  Total Return: ${numberWithCommas(totalReturn)}`);
        }

        const closeInvestmentResult = await closeInvestment({
          isTestingCall: false,
          investmentId: investment.value.id,
          investmentName: investment.value.investmentName,
          investorEmail: investorRepresentativeEmail,
          investorFullName: investorRepresentativeName,
          closeDate: offering.value.closeDate,
          investmentDescription: offering.value.investmentDescription,
          returnDescription: offering.value.returnDescription,
          offeringTypeDescription: offering.value.offeringTypeDescription,
          issuerName: offering.value.issuerName,
          offeringType: offering.value.offeringType,  //debt or equity
          interestRate: offering.value.interestRate,
          maturityDate: maturityDate,
          totalInvestment: numberWithCommas(investment.value.totalInvestment),
          totalInterest: totalInterest,
          totalReturn: numberWithCommas(totalReturn),
          amountRaised: numberWithCommas(offering.value.amountRaised),
          pricePerShare: offering.value.pricePerShare,
          issuerCommonName: offering.value.issuerCommonName,
          spvName: offering.value.spvName,
          initialFee: offering.value.initialFee,
          successFeePercent: offering.value.successFeePercent,
          successFeeAmount: offering.value.successFeeAmount,
          issuerContactName: offering.value.contactName,
          issuerContactEmail: offering.value.contactEmail,
          escrowDate: offering.value.escrowDate,
          maturityYears: offering.value.maturityYears,
          closeDateDescription: offering.value.closeDateDescription
        });
        if (closeInvestmentResult.data.error) {
          errorCancel.value = closeInvestmentResult.data.error;
        }
        isInvesting.value = false;
      } catch (err) {
        errorCancel.value = `Error: ${err.message}`;
      }

    };

    const handleAcceptInvestment = async newStatus => {
      isInvesting.value = true;
      try {
        var cleanOwners = "";
        if (investment.value.owners) {
          cleanOwners = investment.value.owners.map(item => {
            const container = {};
            container.entityOwnerName = item.entityOwnerName;
            container.entityOwnerDateOfBirth = item.entityOwnerDateOfBirth;
            container.entityOwnerSSN =
              item.entityOwnerSSN.length > 4
                ? item.entityOwnerSSN
                  .slice(-4)
                  .padStart(item.entityOwnerSSN.length, "*")
                : item.entityOwnerSSN;
            container.entityOwnerEmail = item.entityOwnerEmail;
            container.entityOwnerStreetAddress1 =
              item.entityOwnerStreetAddress1;
            container.entityOwnerStreetAddress2 =
              item.entityOwnerStreetAddress2;
            container.entityOwnerCity = item.entityOwnerCity;
            container.entityOwnerState = item.entityOwnerState;
            container.entityOwnerPostalCode = item.entityOwnerPostalCode;
            container.entityOwnerCountry = item.entityOwnerCountry;

            return container;
          });
        }
        //2023-03-10: Put Id/Account numbers in private collection - account document
        // Make sure we don't overwrite good numbers (e.g. investment accepted then unaccepted then accept again)
        if (
          investment.value.primarySSN !== "undefined" &&
          investment.value.primarySSN != "*********"
        ) {
          await addInvestmentAccount("account", {
            primarySSN:
              typeof investment.value.primarySSN !== "undefined" &&
                investment.value.primarySSN.length > 4
                ? investment.value.primarySSN
                  .slice(-4)
                  .padStart(investment.value.primarySSN.length, "*")
                : "",
            secondarySSN:
              typeof investment.value.secondarySSN !== "undefined" &&
                investment.value.secondarySSN.length > 4
                ? investment.value.secondarySSN
                  .slice(-4)
                  .padStart(investment.value.secondarySSN.length, "*")
                : "",
            owners: cleanOwners,
            paymentAccountNumber: investment.value.paymentAccountNumber,
            paymentAccountRoutingNumber:
              investment.value.paymentAccountRoutingNumber
          });
        } else {
          errorInvestmentAccount.value = "";
        }
        if (!errorInvestmentAccount.value) {
          await updateInvestment({
            status: newStatus,
            primarySSN: "*********",
            secondarySSN: "*********",
            owners: "",
            paymentAccountNumber: "*********",
            paymentAccountNumberConfirmed: "",
            paymentAccountRoutingNumber: "*********",
            editedAt: timestamp(),
            editedBy: "Admin"
          });
          if (!errorUpdate.value) {
            isInvesting.value = false;
            router.go(-1);
          } else {
            console.log(`Error: ${errorUpdate.value}`);
            isInvesting.value = false;
          }
        } else {
          console.log(`Error: ${errorInvestmentAccount.value}`);
          isInvesting.value = false;
        }
      } catch (err) {
        console.log(`Server Error: ${err.message}`);
        isInvesting.value = false;
      }
    };

    const handleConfirmInvestment = async () => {
      isInvesting.value = true;
      try {
        await updateInvestment({
          paymentAccountType: investment.value.paymentAccountType,
          editedAt: timestamp(),
          editedBy: "Admin"
        });
        if (errorUpdate.value) {
          console.log(`Error: ${errorUpdate.value}`);
          isInvesting.value = false;
        } else {
          await resubmitInvestment({
            investmentId: investment.value.id
          });
          router.go(-1);
          isInvesting.value = false;
        }
      } catch (err) {
        console.log(`Server Error: ${err.message}`);
        isInvesting.value = false;
      }
    };

    return {
      acceptedStatus,
      createdStatus,
      investedStatus,
      isInvesting,
      errorDocument,
      errorOffering,
      errorInvestment,
      errorCancel,
      errorUpdate,
      errorInvestmentAccount,
      errorAccount,
      formatPhoneNumber,
      numberWithCommas,
      toPrettyDate,
      offering,
      investment,
      account,
      cancelPending,
      format,
      router,
      handleAcceptInvestment,
      handleSendTest,
      handleCloseInvestment,
      handleConfirmInvestment,
      handleCancelInvestment,
      handleUpdateCancelReason,
      handleVoidedInvestment,
      isCancellable,
      handleDocumentLoad,
      downloadURL,
      documentShowing
    };
  }
};
</script>

<style>
.btn-link {
  text-decoration: none;
}
</style>