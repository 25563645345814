<template>
  <SubNav class="subnav-position" :selected="'team'" :id="id" />
  <main>
    <form
      v-if="teamMember"
      @submit.prevent="handleSave"
      class="shadow edit-form"
    >
      <div class="float-end">
        <router-link :to="{ name: 'OfferingTeam', params: { id: id } }"
          ><h4>&times;</h4></router-link
        >
      </div>
      <h6>Edit Team Member</h6>
      <div class="form-outline mb-4">
        <label>Name</label>
        <input
          type="text"
          required
          v-model="teamMember.name"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Title</label>
        <input
          type="text"
          required
          v-model="teamMember.title"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Order</label>
        <input
          type="number"
          step="1"
          required
          v-model="teamMember.order"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <div class="founder">
          <img :src="teamMember.headShotURLThumb" />
        </div>
        <div>
          <label>Upload Head Shot (Min 200 x 200)</label>
        </div>
        <div class="error">{{ errorHeadShot }}</div>
        <div v-if="isPending">
          <button class="btn btn-secondary" disabled>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Saving...</span>
            </div>
          </button>
        </div>
        <div v-else>
          <input type="file" @change="handleHeadShot" />
        </div>
      </div>
      <div class="form-outline mb-4">
        <label>Biography</label>
        <QuillEditor
          v-model:content="teamMember.biography"
          contentType="html"
          theme="snow"
        />
      </div>
      <div class="form-outline mb-4">
        <label>LinkedIn</label>
        <input type="text" v-model="teamMember.linkedIn" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Facebook</label>
        <input type="text" v-model="teamMember.facebook" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Instagram</label>
        <input
          type="text"
          v-model="teamMember.instagram"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Twitter</label>
        <input type="text" v-model="teamMember.twitter" class="form-control" />
      </div>
      <!-- <div class="form-outline mb-4">
        <label>TikTok</label>
        <input type="text" v-model="teamMember.tiktok" class="form-control" />
      </div> -->
      <!-- Private Data -->
      <div v-if="teamMemberContact" class="border p-2 bg-light">
        <h5>Private Information (not visible to other users)</h5>
        <div class="form-outline mb-4">
          <label>Email Address</label>
          <input
            type="text"
            v-model="teamMemberContact.email"
            class="form-control"
            @blur="validateEmail"
            required
          />
          <div class="error">{{ emailError }}</div>
        </div>
        <div class="form-outline mb-4">
          <label>Street Address</label>
          <input
            type="text"
            v-model="teamMemberContact.streetAddress1"
            class="form-control"
          />
        </div>
        <div class="form-outline mb-4">
          <label>Apartment, Suite or Unit Number</label>
          <input
            type="text"
            v-model="teamMemberContact.streetAddress2"
            class="form-control"
          />
        </div>
        <div class="form-outline mb-4">
          <label>City</label>
          <input
            type="text"
            v-model="teamMemberContact.city"
            class="form-control"
          />
        </div>
        <div class="form-outline mb-4">
          <label>State</label>
          <input
            type="text"
            v-model="teamMemberContact.region"
            class="form-control"
          />
        </div>
        <div class="form-outline mb-4">
          <label>Postal Code</label>
          <input
            type="text"
            v-model="teamMemberContact.postalCode"
            class="form-control"
          />
        </div>
        <div class="form-outline mb-4">
          <label>Phone Number</label>
          <input
            type="text"
            v-model="teamMemberContact.phoneNumber"
            class="form-control"
          />
        </div>
        <div class="form-outline mb-4">
          <label>Social Security Number</label>
          <input
            type="text"
            v-model="teamMemberContact.taxIdNumber"
            class="form-control"
            @blur="validateTaxId"
          />
          <div class="error">{{ taxIdError }}</div>
        </div>
        <div class="form-outline mb-4">
          <label>Date of Birth</label>
          <input
            type="date"
            v-model="teamMemberContact.dateOfBirth"
            class="form-control"
          />
        </div>
        <div class="form-outline mb-4">
          <label>Special instructions or notes for resolving AML</label>
          <input
            type="text"
            v-model="teamMemberContact.faRelationshipDescription"
            class="form-control"
          />
        </div>
      </div>
      <div class="error">{{ formError }}</div>
      <div v-if="!isPending" class="row text-center">
        <div v-if="!formError && !emailError && !taxIdError" class="col">
          <button class="btn btn-primary">Save</button>
        </div>
        <div v-else class="col">
          <button class="btn btn-secondary" disabled>Save</button>
        </div>
        <div class="col">
          <router-link
            :to="{ name: 'OfferingTeam', params: { id: id } }"
            class="btn btn-secondary"
          >
            Cancel
          </router-link>
        </div>
        <div class="col">
          <span class="btn btn-link text-danger" @click="handleDelete">
            Delete Team Member
          </span>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-secondary" disabled>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Saving...</span>
          </div>
        </button>
      </div>
    </form>
  </main>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { ref } from "@vue/reactivity";
import SubNav from "@/components/navigation/SubNav.vue";
import useDocument from "@/composables/useDocument";
import useStorage from "@/composables/useStorage";
import getDocument from "@/composables/getDocument";
import { useRouter } from "vue-router";
import { timestamp } from "@/firebase/config";
import { computed } from "@vue/runtime-core";

export default {
  props: ["id", "teamMemberId"],
  components: { SubNav, QuillEditor },
  setup(props) {
    const isPending = ref(false);
    const formError = ref(null);
    const router = useRouter();
    const emailError = ref(null);
    const taxIdError = ref(null);
    const validEmail = ref(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    const validTaxId = ref(/(^\d{9})|(^\d{3}-\d{2}-\d{4}$)|(^[1-9]\d?-\d{7}$)/);

    const validateEmail = () => {
      emailError.value = null;
      if (!validEmail.value.test(teamMemberContact.value.email)) {
        emailError.value = "Invalid Email Address.";
      }
    };

    const validateTaxId = () => {
      taxIdError.value = null;
      if (!validTaxId.value.test(teamMemberContact.value.taxIdNumber)) {
        taxIdError.value = "Tax Id should be in the form: 999-99-9999";
      }
    };

    const isReadyToAssociate = computed(() => {
      try {
        return (
          teamMember.value.name &&
          teamMember.value.name.length > 0 &&
          validateEmail &&
          teamMemberContact.value.streetAddress1 &&
          teamMemberContact.value.streetAddress1.length > 0 &&
          teamMemberContact.value.city &&
          teamMemberContact.value.city.length > 0 &&
          teamMemberContact.value.region &&
          teamMemberContact.value.region.length > 0 &&
          teamMemberContact.value.postalCode &&
          teamMemberContact.value.postalCode.length > 0 &&
          validateTaxId &&
          teamMemberContact.value.dateOfBirth &&
          teamMemberContact.value.phoneNumber &&
          teamMemberContact.value.phoneNumber.length > 0
        );
      } catch (err) {
        return false;
      }
    });

    const { document: teamMember } = getDocument(
      "teamMembers",
      props.teamMemberId
    );

    const { document: teamMemberContact } = getDocument(
      `teamMembers/${props.teamMemberId}/private`,
      "contact"
    );

    const {
      error: memberContactError,
      deleteDoc: deleteTeamMemberContact,
      updateDoc: updateMemberContact
    } = useDocument(`teamMembers/${props.teamMemberId}/private`, "contact");

    const {
      error: memberError,
      deleteDoc: deleteTeamMember,
      updateDoc: updateMember
    } = useDocument("teamMembers", props.teamMemberId);

    const {
      error: errorHeadShot,
      url,
      filePath,
      urlThumb,
      filePathThumb,
      urlMedium,
      filePathMedium,
      uploadImageResize,
      deleteImage
    } = useStorage();

    const saveDetails = async () => {
      await updateMember({
        name: teamMember.value.name,
        title: teamMember.value.title,
        order: teamMember.value.order,
        biography: teamMember.value.biography ? teamMember.value.biography : "",
        linkedIn: teamMember.value.linkedIn ? teamMember.value.linkedIn : "",
        facebook: teamMember.value.facebook ? teamMember.value.facebook : "",
        instagram: teamMember.value.instagram ? teamMember.value.instagram : "",
        twitter: teamMember.value.twitter ? teamMember.value.twitter : "",
        tiktok: teamMember.value.tiktok ? teamMember.value.tiktok : "",
        headShotFilePath: teamMember.value.headShotFilePath
          ? teamMember.value.headShotFilePath
          : "",
        headShotURL: teamMember.value.headShotURL
          ? teamMember.value.headShotURL
          : "",
        headShotFilePathThumb: teamMember.value.headShotFilePathThumb
          ? teamMember.value.headShotFilePathThumb
          : "",
        headShotURLThumb: teamMember.value.headShotURLThumb
          ? teamMember.value.headShotURLThumb
          : "",
        headShotFilePathMedium: teamMember.value.headShotFilePathMedium
          ? teamMember.value.headShotFilePathMedium
          : "",
        headShotURLMedium: teamMember.value.headShotURLMedium
          ? teamMember.value.headShotURLMedium
          : "",
        faRelationshipId: teamMember.value.faRelationshipId
          ? teamMember.value.faRelationshipId
          : "",
        faEntityPersonId: teamMember.value.faEntityPersonId
          ? teamMember.value.faEntityPersonId
          : "",
        editedAt: timestamp()
      });
      await updateMemberContact({
        email: teamMemberContact.value.email
          ? teamMemberContact.value.email
          : "",
        streetAddress1: teamMemberContact.value.streetAddress1
          ? teamMemberContact.value.streetAddress1
          : "",
        streetAddress2: teamMemberContact.value.streetAddress2
          ? teamMemberContact.value.streetAddress2
          : "",
        city: teamMemberContact.value.city ? teamMemberContact.value.city : "",
        region: teamMemberContact.value.region
          ? teamMemberContact.value.region
          : "",
        postalCode: teamMemberContact.value.postalCode
          ? teamMemberContact.value.postalCode
          : "",
        taxIdNumber: teamMemberContact.value.taxIdNumber
          ? teamMemberContact.value.taxIdNumber
          : "",
        dateOfBirth: teamMemberContact.value.dateOfBirth
          ? teamMemberContact.value.dateOfBirth
          : "",
        phoneNumber: teamMemberContact.value.phoneNumber
          ? teamMemberContact.value.phoneNumber
          : "",
        faRelationshipDescription: teamMemberContact.value
          .faRelationshipDescription
          ? teamMemberContact.value.faRelationshipDescription
          : "",
        editedAt: timestamp()
      });
      if (memberContactError.value) {
        memberError.value = memberError.value + " " + memberContactError.value;
      }
    };

    const handleSave = async () => {
      try {
        isPending.value = true;
        formError.value = "";
        await saveDetails();
        isPending.value = false;
        if (memberError.value) {
          formError.value = memberError.value;
          //TODO delete image here
        } else {
          console.log("Member Saved");
          router.push({ name: "OfferingTeam", params: { id: props.id } });
        }
      } catch (err) {
        formError.value = "System Error: " + err.message;
      }
    };

    const handleDelete = async () => {
      isPending.value = true;
      formError.value = null;
      try {
        if (teamMember.value.headShotFilePath) {
          await deleteImage(teamMember.value.headShotFilePath);
        }
        if (teamMember.value.headShotFilePathThumb) {
          await deleteImage(teamMember.value.headShotFilePathThumb);
        }
        if (teamMember.value.headShotFilePathMedium) {
          await deleteImage(teamMember.value.headShotFilePathMedium);
        }
        await deleteTeamMemberContact();
        if (memberContactError.value) {
          formError.value = memberContactError.value;
        } else {
          await deleteTeamMember();
          isPending.value = false;
          if (memberError.value) {
            formError.value = memberError.value;
          } else {
            router.push({ name: "OfferingTeam", params: { id: props.id } });
          }
        }

        isPending.value = false;
      } catch (err) {
        console.log(`faDeleteAssociation Failed: ${err}`);
        formError.value = "faDeleteAssociation Failed with: " + err.message;
        isPending.value = false;
      }
    };

    // allowed file types
    const types = ["image/png", "image/jpeg"];

    const handleHeadShot = async e => {
      isPending.value = true;
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        errorHeadShot.value = null;
        //Delete the existing images so our resize doesn't break
        if (teamMember.value.headShotFilePath) {
          await deleteImage(teamMember.value.headShotFilePath);
          console.log(`Storage Update: ${errorHeadShot.value}`);
          teamMember.value.headShotFilePath = "";
          teamMember.value.headShotURL = "";
        }
        if (teamMember.value.headShotFilePathThumb) {
          await deleteImage(teamMember.value.headShotFilePathThumb);
          console.log(`Storage Update: ${errorHeadShot.value}`);
          teamMember.value.headShotFilePathThumb = "";
          teamMember.value.headShotURLThumb = "";
        }
        if (teamMember.value.headShotFilePathMedium) {
          await deleteImage(teamMember.value.headShotFilePathMedium);
          console.log(`Storage Update: ${errorHeadShot.value}`);
          teamMember.value.headShotFilePathMedium = "";
          teamMember.value.headShotURLMedium = "";
        }
        errorHeadShot.value = null;
        await uploadImageResize(
          `teamMembers/${props.id}/${props.teamMemberId}`,
          "headShot",
          selected,
          teamMember.value.issuerId
        );
        if (errorHeadShot.value) {
          formError.value = errorHeadShot.value;
        } else {
          teamMember.value.headShotFilePath = filePath.value;
          teamMember.value.headShotURL = url.value;
          teamMember.value.headShotFilePathThumb = filePathThumb.value;
          teamMember.value.headShotURLThumb = urlThumb.value;
          teamMember.value.headShotFilePathMedium = filePathMedium.value;
          teamMember.value.headShotURLMedium = urlMedium.value;
          await saveDetails();
          if (updateMember.value) {
            formError.value = updateMember.value;
          }
        }
      } else {
        formError.value = errorHeadShot.value =
          "Please select an image file (png or jpeg)";
      }
      isPending.value = false;
    };

    return {
      isPending,
      formError,
      errorHeadShot,
      handleSave,
      handleHeadShot,
      handleDelete,
      teamMember,
      teamMemberContact,
      validateEmail,
      emailError,
      taxIdError,
      validateTaxId,
      isReadyToAssociate
    };
  }
};
</script>

<style>
</style>