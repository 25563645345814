<template>
  <main>
    <router-link
      :to="{ name: 'CreateFeature' }"
      class="btn btn-primary float-end"
    >
      Create Feature
    </router-link>
    <h4>Features</h4>
    <div v-if="error" class="error">
      {{ error }}
    </div>
    <div v-if="features">
      <div v-for="feature in features" :key="feature.id">
        <router-link
          :to="{ name: 'FeatureDetails', params: { id: feature.id } }"
        >
          <div class="single">
            <div class="thumbnail">
              <img :src="feature.imageURL" />
            </div>
            <div class="info">
              <h4>{{ feature.headline }}</h4>
              <h5>{{ feature.subheading }}</h5>
              <p>{{ feature.description }}</p>
            </div>
            <div class="order">
              <p>Order: {{ feature.order }}</p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>
import getCollection from "@/composables/getCollection";
export default {
  setup() {
    const { error, documents: features } = getCollection("rhFeatures", null, [
      "order",
      "asc"
    ]);
    return { error, features };
  }
};
</script>
<style scoped>
.single {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  background: white;
  margin: 16px 0;
  transition: all ease 0.2s;
  text-decoration: none;
  overflow: hidden;
}
.single:hover {
  box-shadow: 1px 2px 3px rgba(50, 50, 50, 0.05);
  transform: scale(1.02);
  transition: all ease 0.2s;
}
.thumbnail {
  max-width: 100px;
  max-height: 100px;
  overflow: hidden;
  border-radius: 10px;
}
img {
  max-width: 150%;
  max-height: 150%;
  display: block;
}
.info {
  margin: 0 30px;
}
a {
  text-decoration: none;
}
.order {
  margin-left: auto;
}
</style>
