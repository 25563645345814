<template>
  <main>
    <div class="add-carousel-item">
      <form @submit.prevent="handleSubmit">
        <h4>Create a Carousel Item</h4>
        <div class="form-outline mb-4">
          <input
            type="text"
            placeholder="Teaser"
            v-model="teaser"
            class="form-control"
          />
        </div>
        <div class="form-outline mb-4">
          <input
            type="text"
            placeholder="Headline"
            required
            v-model="headline"
            class="form-control"
          />
        </div>
        <div class="form-outline mb-4">
          <textarea
            placeholder="Subheading"
            v-model="subheading"
            class="form-control"
          />
        </div>
        <div class="form-outline mb-4">
          <label>Sort Order</label>
          <input
            type="number"
            min="0"
            max="10"
            step="1"
            placeholder="Order"
            v-model="order"
            required
            class="form-control"
          />
        </div>
        <div class="form-outline mb-4">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexCheckChecked"
              v-model="isVideo"
            />
            <label class="form-check-label" for="flexCheckChecked">
              Video Item?
            </label>
          </div>
        </div>
        <div v-if="isVideo" class="form-outline mb-4">
          <input
            type="text"
            placeholder="Video URL"
            required
            v-model="videoURL"
            class="form-control"
          />
        </div>
        <div v-else class="form-outline mb-4">
          <div>
            <label>Upload Carousel Image</label>
          </div>
          <input type="file" @change="handleChange" />
        </div>
        <div class="error">{{ formError }}</div>
        <div v-if="!isPending">
          <router-link
            :to="{ name: 'CarouselItems' }"
            class="btn btn-secondary btn-block float-end"
          >
            Cancel
          </router-link>
          <button class="btn btn-primary btn-block">Create</button>
        </div>
        <div v-else>
          <button class="btn btn-primary btn-block" disabled>
            Creating...
          </button>
        </div>
      </form>
    </div>
  </main>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import useStorage from "@/composables/useStorage";
import useCollection from "@/composables/useCollection";
import useDocument from "@/composables/useDocument";
import getUser from "@/composables/getUser";
import { timestamp } from "@/firebase/config";

export default {
  setup() {
    const formError = ref(null);
    const teaser = ref("");
    const headline = ref("");
    const subheading = ref("");
    const order = ref(0);
    const file = ref(null);
    const videoURL = ref("");
    const isPending = ref(false);
    const isVideo = ref(false);
    const { error: docError, addDoc } = useCollection("rhCarousels");
    const { error: storageError, filePath, url, uploadImage } = useStorage();
    const { user } = getUser();
    const router = useRouter();

    const handleSubmit = async () => {
      try {
        isPending.value = true;
        formError.value = "";
        const res = await addDoc({
          teaser: teaser.value,
          headline: headline.value,
          subheading: subheading.value,
          isVideo: isVideo.value,
          videoURL: videoURL.value,
          imageFilePath: "",
          imageURL: "",
          order: parseInt(order.value),
          createdBy: user.value.uid,
          createdAt: timestamp()
        });
        if (docError.value) {
          formError.value = docError.value;
        } else {
          console.log("Added CarouselID: ", res.id);
          if (file.value && !isVideo.value) {
            await uploadImage(
              `rhCarousels/${res.id}`,
              "carouselImage",
              file.value
            );
            if (!storageError.value && filePath.value && url.value) {
              const { updateDoc } = useDocument("rhCarousels", res.id);
              await updateDoc({
                imageFilePath: filePath.value,
                imageURL: url.value,
                editedAt: timestamp()
              });
              if (docError.value) {
                formError.value = docError.value;
              }
            } else {
              formError.value = "Error uploading Image" + storageError.value;
            }
          }
          isPending.value = false;
          if (!formError.value) {
            router.push({
              name: "CarouselItems"
            });
          }
        }
        isPending.value = false;
      } catch (err) {
        formError.value = "System Error: " + err.message;
      }
    };

    // allowed file types
    const types = ["image/png", "image/jpeg"];

    const handleChange = e => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        formError.value = null;
      } else {
        file.value = null;
        formError.value = "Please select an image file (png or jpeg)";
      }
    };

    return {
      isPending,
      formError,
      teaser,
      headline,
      subheading,
      order,
      handleSubmit,
      file,
      handleChange,
      videoURL,
      isVideo
    };
  }
};
</script>

<style scoped>
form {
  max-width: 100%;
  text-align: left;
}
</style>