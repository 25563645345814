<template>
  <main>
    <form
      v-if="article"
      @submit.prevent="handleSubmit"
      class="shadow edit-form"
    >
      <div class="float-end">
        <router-link :to="{ name: 'News' }"><h4>&times;</h4></router-link>
      </div>
      <h4>News Article</h4>
      <div class="form-outline mb-4">
        <label>Headline</label>
        <input
          type="text"
          placeholder="Name"
          v-model="article.headline"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Story Content</label>
        <textarea
          placeholder="Story Content"
          v-model="article.story"
          class="form-control long-text"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Show on:</label>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="showOnAbout"
            v-model="article.showOnAboutPage"
          />
          <label class="form-check-label" for="flexCheckChecked">
            About Page
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="showOnNews"
            v-model="article.showOnNewsPage"
          />
          <label class="form-check-label" for="flexCheckChecked">
            Knowledge Base
          </label>
        </div>
      </div>
      <div class="form-outline mb-4">
        <label>Sort Order (0 not shown)</label>
        <input
          type="number"
          min="0"
          max="100"
          step="1"
          v-model="article.order"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <div class="thumbnail">
          <img :src="article.imageURL" />
        </div>
        <div>
          <label>Upload News Story Image (200 x 200)</label>
        </div>
        <input type="file" @change="handleChange" />
      </div>
      <div class="error">{{ formError }}</div>
      <div v-if="isPending">
        <button class="btn btn-primary" disabled>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Saving...</span>
          </div>
        </button>
      </div>
      <div v-else class="row text-center">
        <div class="col">
          <button class="btn btn-primary">Save</button>
        </div>
        <div class="col">
          <router-link :to="{ name: 'News' }" class="btn btn-secondary">
            Cancel
          </router-link>
        </div>
        <div class="col">
          <span
            class="btn btn-link text-danger"
            data-bs-toggle="modal"
            data-bs-target="#confirmDeleteModal"
          >
            Delete Item
          </span>
        </div>
      </div>
    </form>
    <ModalDelete title="Delete News Article?" @confirm-delete="handleDelete">
      Are you sure you want to permanently delete this News Article?
    </ModalDelete>
  </main>
</template>

<script>
import { useRouter } from "vue-router";
import useStorage from "@/composables/useStorage";
import getDocument from "@/composables/getDocument";
import useDocument from "@/composables/useDocument";
import { timestamp } from "@/firebase/config";
import { ref } from "@vue/reactivity";
import ModalDelete from "@/components/modals/ModalDelete";

export default {
  props: ["id"],
  components: { ModalDelete },
  setup(props) {
    const formError = ref(null);
    const isPending = ref(false);
    const {
      error: storageError,
      url,
      filePath,
      urlThumb,
      filePathMedium,
      urlMedium,
      filePathThumb,
      uploadImageResize,
      deleteImage
    } = useStorage();
    const { document: article } = getDocument("rhNews", props.id);
    const { error: docError, updateDoc, deleteDoc } = useDocument(
      "rhNews",
      props.id
    );
    const file = ref(null);
    const router = useRouter();

    const handleSubmit = async () => {
      try {
        formError.value = null;
        storageError.value = null;
        isPending.value = true;
        if (storageError.value) {
          formError.value = storageError.value;
        } else {
          await updateDoc({
            headline: article.value.headline,
            story: article.value.story,
            order: parseInt(article.value.order),
            showOnAboutPage:
              typeof article.value.showOnAboutPage === "undefined"
                ? false
                : article.value.showOnAboutPage,
            showOnNewsPage:
              typeof article.value.showOnNewsPage === "undefined"
                ? false
                : article.value.showOnNewsPage,
            editedAt: timestamp()
          });
          isPending.value = false;
          if (docError.value) {
            formError.value = docError.value;
          } else {
            router.push({
              name: "News"
            });
          }
        }
        isPending.value = false;
      } catch (err) {
        formError.value = "System Error: " + err.message;
      }
    };

    // allowed file types
    const types = ["image/png", "image/jpeg"];

    const handleChange = async e => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        isPending.value = true;
        file.value = selected;
        formError.value = null;
        //Delete the existing images so our resize doesn't break
        if (article.value.imageFilePath) {
          await deleteImage(article.value.imageFilePath);
          console.log(`Storage Update: ${storageError.value}`);
          article.value.imageFilePath = "";
          article.value.imageURL = "";
        }
        if (article.value.imageFilePathMedium) {
          await deleteImage(article.value.imageFilePathMedium);
          console.log(`Storage Update: ${storageError.value}`);
          article.value.imageFilePathMedium = "";
          article.value.imageURLThumb = "";
        }
        if (article.value.imageFilePathThumb) {
          await deleteImage(article.value.imageFilePathThumb);
          console.log(`Storage Update: ${storageError.value}`);
          article.value.imageFilePathThumb = "";
          article.value.imageURLThumb = "";
        }
        storageError.value = null;
        await uploadImageResize(
          `rhNews/${article.value.id}`,
          "newsImage",
          file.value
        );
        if (storageError.value) {
          formError.value = storageError.value;
        } else {
          await updateDoc({
            headline: article.value.headline,
            story: article.value.story,
            order: parseInt(article.value.order),
            showOnAboutPage:
              typeof article.value.showOnAboutPage === "undefined"
                ? false
                : article.value.showOnAboutPage,
            showOnNewsPage:
              typeof article.value.showOnNewsPage === "undefined"
                ? false
                : article.value.showOnNewsPage,
            imageFilePath: filePath.value,
            imageURL: url.value,
            imageFilePathMedium: filePathMedium.value,
            imageURLMedium: urlMedium.value,
            imageFilePathThumb: filePathThumb.value,
            imageURLThumb: urlThumb.value,
            editedAt: timestamp()
          });
          if (docError.value) {
            formError.value = docError.value;
          }
        }
        isPending.value = false;
      } else {
        file.value = null;
        formError.value = "Please select an image file (png or jpeg)";
      }
    };

    const handleDelete = async () => {
      isPending.value = true;
      formError.value = null;
      storageError.value = null;
      if (article.value.imageFilePath) {
        await deleteImage(article.value.imageFilePath);
      }
      if (article.value.imageFilePathMedium) {
        await deleteImage(article.value.imageFilePathMedium);
      }
      if (article.value.imageFilePathThumb) {
        await deleteImage(article.value.imageFilePathThumb);
      }
      storageError.value = null;
      await deleteDoc();
      if (docError.value) {
        formError.value = docError.value;
      } else {
        router.push({ name: "News" });
      }
      isPending.value = false;
    };

    return {
      formError,
      isPending,
      handleDelete,
      article,
      handleSubmit,
      handleChange
    };
  }
};
</script>

<style>
</style>