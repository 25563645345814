<template>
  <main>
    <h3>Documents</h3>
    <p>List of documents goes here.</p>
  </main>
</template>

<script>
export default {
  name: "Documents"
};
</script>

<style>
</style>