<template>
  <main>
    <div class="page-content">
      <h4>Issuers</h4>
      <div v-if="users">
        <div v-for="user in users" :key="user.id">
          <div class="row ">
            <div class="name col-md">
              {{ user.name }}
            </div>
            <div class="name col-md">
              {{ user.email }}
            </div>
            <div class="name col-md">
              {{ user.createdAt.toDate() }}
            </div>
          </div>
        </div>
        <div class="text-center" v-if="users && users.length == 0">
          <p>
            This is where the issuers will be listed once an issuer is created
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import getCollection from "@/composables/getCollection";

export default {
  name: "Issuers",
  setup() {
    const { error, documents: users } = getCollection(
      "users",
      [["role", "==", "issuer"]],
      ["name", "asc"]
    );

    return { error, users };
  }
};
</script>
