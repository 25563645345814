<template>
  <footer class="footer fixed-bottom bg-light">
    <p class="float-end"><a href="#">Back to top</a></p>
    <p>
      © 2021-2023 Round Here, LLC. ·
      <a href="https://roundhere.co/rh/privacy">Privacy</a> ·
      <a href="https://roundhere.co/rh/terms">Terms</a>
    </p>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
p {
  margin-bottom: 0px;
}
</style>
