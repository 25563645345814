<template>
  <main>
    <router-link
      :to="{ name: 'CreateCarouselItem' }"
      class="btn btn-primary float-end"
    >
      Create Carousel Item
    </router-link>
    <h2>Carousel Items</h2>
    <div v-if="error" class="error">{{ error }}</div>
    <div v-if="carouselItems">
      <div v-for="carouselItem in carouselItems" :key="carouselItem.id">
        <router-link
          :to="{ name: 'CarouselItemDetails', params: { id: carouselItem.id } }"
        >
          <div class="single">
            <div class="thumbnail">
              <img :src="carouselItem.imageURL" />
            </div>
            <div class="info">
              <p>{{ carouselItem.teaser }}</p>
              <h4>{{ carouselItem.headline }}</h4>
              <p>{{ carouselItem.subheading }}</p>
            </div>
            <div class="order">
              <p>Order: {{ carouselItem.order }}</p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>
import getCollection from "@/composables/getCollection";
export default {
  setup() {
    const { error, documents: carouselItems } = getCollection(
      "rhCarousels",
      null,
      ["order", "asc"]
    );

    console.log(carouselItems);
    return { error, carouselItems };
  }
};
</script>

<style scoped>
.single {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  background: white;
  margin: 16px 0;
  transition: all ease 0.2s;
  text-decoration: none;
  overflow: hidden;
}
.single:hover {
  box-shadow: 1px 2px 3px rgba(50, 50, 50, 0.05);
  transform: scale(1.02);
  transition: all ease 0.2s;
}
.thumbnail {
  max-width: 100px;
  max-height: 100px;
  overflow: hidden;
  border-radius: 10px;
}
img {
  max-width: 150%;
  max-height: 150%;
  display: block;
}
.info {
  margin: 0 30px;
}

a {
  text-decoration: none;
}

.order {
  margin-left: auto;
}
</style>
