<template>
  <main>
    <router-link
      :to="{ name: 'CreateInvestor' }"
      class="btn btn-primary float-end"
      >Create Investor</router-link
    >
    <div class="page-content">
      <h4>Investors</h4>
      <div v-if="investments">
        <button v-if="isReporting" class="btn btn-secondary" disabled>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Creating report now...</span>
          </div>
        </button>
        <div v-else>
          <button class="btn btn-primary mb-4" @click="handleReport">
            Send Investment Report
          </button>
        </div>
        <router-link :to="{ name: 'InvestorDrafts' }">Go To Drafts</router-link>
        <div v-if="error">{{ error }}</div>
        <div v-for="investment in investments" :key="investment.id">
          <router-link
            class="offering-link"
            :to="{
              name: 'InvestmentDetails',
              params: {
                offeringId: investment.offeringId,
                investmentId: investment.id
              }
            }"
          >
            <div
              class="row single "
              :class="{
                newInvestment: investment.status == 'created',
                c2mError: investment.status == 'C2MError',
                cancelInvestment: investment.status == 'cancelled',
                acceptedInvestment: investment.status == 'accepted'
              }"
            >
              <div
                v-if="investment.investmentType == 'entity'"
                class="name col-md"
              >
                {{ investment.entityName }}
              </div>
              <div v-else class="name col-md">
                {{ investment.primaryFullName }}
              </div>
              <div class="name col-md">
                {{ investment.offeringName }}
              </div>
              <div class="name col-md">
                {{ investment.investmentType }}
              </div>
              <div class="name col-md">
                ${{ numberWithCommas(investment.totalInvestment) }}
              </div>
              <div class="name col-md">
                {{ investment.status }}
              </div>
              <div class="name col-md-1">{{ investment.paymentMethod }}</div>
              <div class="name col-md-3">
                {{ investment.createdAt.toDate().toLocaleString() }}
              </div>
            </div>
          </router-link>
        </div>
        <div class="text-center" v-if="investments && investments.length == 0">
          <p>
            This is where the investments will be listed once an investor
            invests in your offering.
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import getCollection from "@/composables/getCollection";
import usePrettyNumbers from "@/composables/usePrettyNumbers";
import { ref } from "@vue/runtime-core";
import { projectFunctions } from "@/firebase/config";

export default {
  setup() {
    const isReporting = ref(false);
    const { error, documents: investments } = getCollection(
      "investments",
      null,
      ["createdAt", "desc"]
    );
    const makeReport = projectFunctions.httpsCallable("makeInvestmentReport");
    const { numberWithCommas } = usePrettyNumbers();

    const handleReport = async () => {
      try {
        isReporting.value = true;
        const newReport = await makeReport();
        isReporting.value = false;
        console.log(`Report Status: ${newReport.data.status}`);
        if (newReport.error) {
          console.log(`Reporting error: ${newReport.error}`);
        }
      } catch (err) {
        console.log(`Server Error: ${err.message}`);
      }
    };

    return {
      investments,
      error,
      numberWithCommas,
      isReporting,
      handleReport
    };
  }
};
</script>

<style>
</style>