<template>
  <main>
    <router-link :to="{ name: 'CreateNews' }" class="btn btn-primary float-end">
      Create News
    </router-link>
    <h4>News</h4>
    <div v-if="error" class="error">
      {{ error }}
    </div>
    <div v-if="news">
      <div v-for="article in news" :key="article.id">
        <router-link :to="{ name: 'NewsDetails', params: { id: article.id } }">
          <h4>{{ article.headline }}</h4>
          <div class="single">
            <div class="clearfix">
              <div class="thumbnail">
                <img :src="article.imageURL" />
              </div>
              <p
                class="story-text"
                v-html="article.story.substring(0, 350) + '...(more)'"
              ></p>
              <div class="order">
                <p>Order: {{ article.order }}</p>
                <p v-if="article.showOnAboutPage">Showing on About Page</p>
                <p v-if="article.showOnNewsPage">Showing in Knowledge Base</p>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>
import getCollection from "@/composables/getCollection";

export default {
  setup() {
    const { error, documents: news } = getCollection("rhNews", null, [
      "order",
      "asc"
    ]);
    return { error, news };
  }
};
</script>

<style>
.single {
  display: block;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  background: white;
  margin: 16px 0;
  transition: all ease 0.2s;
  text-decoration: none;
  overflow: hidden;
}
.single:hover {
  box-shadow: 1px 2px 3px rgba(50, 50, 50, 0.05);
  transform: scale(1.02);
  transition: all ease 0.2s;
}
.thumbnail {
  max-width: 100px;
  max-height: 100px;
  overflow: hidden;
  border-radius: 10px;
}
img {
  max-width: 150%;
  max-height: 150%;
  display: block;
}
.info {
  margin: 0 30px;
}
a {
  text-decoration: none;
}
.order {
  margin-left: auto;
}

.story-text {
  text-decoration: none;
}
</style>