<template>
  <SubNav class="subnav-position" :selected="'pitch'" :id="id" />
  <main>
    <form v-if="offering" @submit.prevent="handleSave" class="edit-form">
      <div class="form-outline mb-4">
        <label>Pitch Highlights</label>
        <QuillEditor
          v-model:content="offering.highlights"
          contentType="html"
          theme="snow"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Embed Pitch Link</label>
        <input type="text" v-model="offering.videoLink" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Problem</label>
        <QuillEditor
          v-model:content="offering.problem"
          contentType="html"
          theme="snow"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Solution</label>
        <QuillEditor
          v-model:content="offering.solution"
          contentType="html"
          theme="snow"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Product</label>
        <QuillEditor
          v-model:content="offering.product"
          contentType="html"
          theme="snow"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Business Model</label>
        <QuillEditor
          v-model:content="offering.businessModel"
          contentType="html"
          theme="snow"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Traction</label>
        <QuillEditor
          v-model:content="offering.traction"
          contentType="html"
          theme="snow"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Recognition</label>
        <QuillEditor
          v-model:content="offering.recognition"
          contentType="html"
          theme="snow"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Market</label>
        <QuillEditor
          v-model:content="offering.market"
          contentType="html"
          theme="snow"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Competition</label>
        <QuillEditor
          v-model:content="offering.competition"
          contentType="html"
          theme="snow"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Vision</label>
        <QuillEditor
          v-model:content="offering.vision"
          contentType="html"
          theme="snow"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Risks</label>
        <QuillEditor
          v-model:content="offering.risks"
          contentType="html"
          theme="snow"
        />
      </div>
      <div class="error">{{ formError }}</div>
      <div v-if="!isPending" class="row text-center">
        <div class="col">
          <button class="btn btn-primary">Save</button>
        </div>
        <div class="col">
          <router-link :to="{ name: 'Offerings' }" class="btn btn-secondary">
            Cancel
          </router-link>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-primary" disabled>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Saving...</span>
          </div>
        </button>
      </div>
    </form>
  </main>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import SubNav from "@/components/navigation/SubNav.vue";
import getDocument from "@/composables/getDocument";
import useDocument from "@/composables/useDocument";
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";

export default {
  props: ["id"],
  components: { SubNav, QuillEditor },
  setup(props) {
    const isPending = ref(false);
    const formError = ref(null);
    const router = useRouter();

    const { document: offering } = getDocument("offeringPitches", props.id);
    const { error: offeringError, updateDoc: updateOffering } = useDocument(
      "offeringPitches",
      props.id
    );

    const handleSave = async () => {
      try {
        isPending.value = true;
        formError.value = "";
        await updateOffering({
          highlights: offering.value.highlights
            ? offering.value.highlights
            : "",
          videoLink: offering.value.videoLink ? offering.value.videoLink : "",
          problem: offering.value.problem ? offering.value.problem : "",
          solution: offering.value.solution ? offering.value.solution : "",
          product: offering.value.product ? offering.value.product : "",
          businessModel: offering.value.businessModel
            ? offering.value.businessModel
            : "",
          traction: offering.value.traction ? offering.value.traction : "",
          recognition: offering.value.recognition
            ? offering.value.recognition
            : "",
          market: offering.value.market ? offering.value.market : "",
          competition: offering.value.competition
            ? offering.value.competition
            : "",
          vision: offering.value.vision ? offering.value.vision : "",
          risks: offering.value.risks ? offering.value.risks : ""
        });
        isPending.value = false;
        if (offeringError.value) {
          formError.value = offeringError.value;
          //TODO delete image here
        } else {
          router.push({ name: "OfferingTeam", params: { id: props.id } });
        }
      } catch (err) {
        formError.value = "System Error: " + err.message;
      }
    };

    return { isPending, formError, offering, handleSave };
  }
};
</script>

<style>
</style>