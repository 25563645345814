<template>
  <main>
    <div class="create-founder">
      <form @submit.prevent="handleSubmit">
        <h4>Create a Founder</h4>
        <label>Name</label>
        <div class="form-outline mb-4">
          <input
            type="text"
            placeholder="Name"
            v-model="name"
            class="form-control"
          />
        </div>
        <div class="form-outline mb-4">
          <label>Title</label>
          <input
            type="text"
            placeholder="Title"
            v-model="title"
            class="form-control"
          />
        </div>
        <div class="form-outline mb-4">
          <label>Description</label>
          <textarea
            placeholder="Description"
            v-model="description"
            class="form-control long-text"
          />
        </div>
        <div class="form-outline mb-4">
          <label>Sort Order (0 not shown)</label>
          <input
            type="number"
            min="0"
            max="10"
            step="1"
            v-model="order"
            class="form-control"
            required
          />
        </div>
        <div class="form-outline mb-4">
          <div>
            <label>Upload Founder Image (200 x 200)</label>
          </div>
          <input type="file" @change="handleChange" />
        </div>
        <div class="error">{{ formError }}</div>
        <div v-if="!isPending">
          <router-link
            :to="{ name: 'Founders' }"
            class="btn btn-secondary btn-block float-end"
          >
            Cancel
          </router-link>
          <button class="btn btn-primary btn-block">
            Create
          </button>
        </div>
        <div v-else>
          <button class="btn btn-primary" disabled>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Saving...</span>
            </div>
          </button>
        </div>
      </form>
    </div>
  </main>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import useStorage from "@/composables/useStorage";
import useCollection from "@/composables/useCollection";
import useDocument from "@/composables/useDocument";
import getUser from "@/composables/getUser";
import { timestamp } from "@/firebase/config";

export default {
  setup() {
    const isPending = ref(false);
    const formError = ref(null);
    const name = ref("");
    const title = ref("");
    const description = ref("");
    const order = ref(0);
    const file = ref(null);

    const { error: docError, addDoc } = useCollection("rhFounders");
    const {
      error: storageError,
      filePath,
      url,
      filePathMedium,
      urlMedium,
      filePathThumb,
      urlThumb,
      uploadImageResize
    } = useStorage();
    const { user } = getUser();
    const router = useRouter();

    // allowed file types
    const types = ["image/png", "image/jpeg"];

    const handleChange = e => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        formError.value = null;
      } else {
        file.value = null;
        formError.value = "Please select an image file (png or jpeg)";
      }
    };

    const handleSubmit = async () => {
      try {
        isPending.value = true;
        formError.value = null;
        storageError.value = null;
        const res = await addDoc({
          name: name.value,
          title: title.value,
          description: description.value,
          order: parseInt(order.value),
          createdBy: user.value.uid,
          createdAt: timestamp()
        });
        if (docError.value) {
          formError.value = docError.value;
        } else {
          if (file.value) {
            await uploadImageResize(
              `rhFounders/${res.id}`,
              "founderImage",
              file.value
            );
            if (!storageError.value && filePath.value && url.value) {
              const { updateDoc } = useDocument("rhFounders", res.id);
              await updateDoc({
                imageFilePath: filePath.value,
                imageURL: url.value,
                imageFilePathThumb: filePathThumb.value,
                imageURLThumb: urlThumb.value,
                imageFilePathMedium: filePathMedium.value,
                imageURLMedium: urlMedium.value,
                editedAt: timestamp()
              });
              if (docError.value) {
                formError.value = docError.value;
              } else {
                router.push({
                  name: "Founders"
                });
              }
            } else {
              formError.value = "Error uploading Image " + storageError.value;
            }
          }
        }
        router.push({
          name: "Founders"
        });
        isPending.value = false;
      } catch (err) {
        formError.value = "System Error: " + err.message;
      }
    };

    return {
      isPending,
      formError,
      name,
      title,
      description,
      order,
      file,
      handleChange,
      handleSubmit
    };
  }
};
</script>

<style scoped>
form {
  max-width: 100%;
  text-align: left;
}
</style>