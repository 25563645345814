<template>
  <SubNav class="subnav-position" :selected="'comments'" :id="id" />
  <main>
    <div class="subnav-detail">
      <div v-if="error">{{ error }}</div>
      <div
        v-for="offeringComment in offeringComments"
        :key="offeringComment.id"
      >
        <router-link
          :to="{
            name: 'ReplyComment',
            params: { id: id, commentId: offeringComment.id }
          }"
        >
          <div
            class="row single"
            :class="{
              deleteRequest: offeringComment.requestDelete
            }"
          >
            <div class="col">
              <div class="subject">
                {{ offeringComment.subject }}
              </div>
              <div class="name">
                {{ offeringComment.commenterDisplayName }}
              </div>
              <div class="legal" v-if="offeringComment.isHyping">
                *issuer founder, employee, or otherwise compensated to promote
                this offering*
              </div>
              <div class="created-at">
                {{ offeringComment.date }}
              </div>
              <div
                class="created-at"
                :class="{
                  deleteRequestColor: offeringComment.replyRequestDelete
                }"
              >
                Reply ({{ offeringComment.replyCount }})
              </div>
              <div v-if="offeringComment.requestDelete" class="name">
                Delete Requested
              </div>
            </div>
            <div class="col message">
              {{ offeringComment.comment }}
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>
import SubNav from "@/components/navigation/SubNav.vue";
import getCollection from "@/composables/getCollection";
import getUser from "@/composables/getUser.js";

export default {
  props: ["id"],
  components: { SubNav },
  setup(props) {
    const { user } = getUser();

    const { error, documents: offeringComments } = getCollection(
      "offeringComments",
      [["offeringId", "==", props.id]],
      ["updatedAt", "desc"]
    );

    return { error, offeringComments, user };
  }
};
</script>

<style>
.unread {
  color: black !important;
  font-weight: bold !important;
}
.deleteRequest {
  border: solid 2px red;
}
.deleteRequestColor {
  color: red;
}
</style>