<template>
  <div id="v-model-select">
    <select
      required
      v-model="issuer"
      @change="handleChange"
      class="form-select"
    >
      <option disabled value="">Please select one</option>
      <option v-for="user in users" :key="user.id" :value="user.id">{{
        user.name
      }}</option>
    </select>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import getCollection from "@/composables/getCollection";

export default {
  props: ["selectedIssuer"],
  emits: ["selectIssuer"],
  setup(props, context) {
    const issuer = ref(props.selectedIssuer);

    const { error, documents: users } = getCollection(
      "users",
      [["role", "==", "issuer"]],
      ["name", "asc"]
    );

    const handleChange = () => {
      console.log(`Selected: ${issuer.value}`);
      context.emit("selectIssuer", issuer.value);
    };

    return { issuer, error, users, handleChange };
  }
};
</script>

<style>
</style>