<template>
  <SubNav class="subnav-position" :selected="'investments'" :id="id" />
  <main>
    <form
      v-if="investment"
      @submit.prevent="handleSave"
      class="shadow edit-form"
    >
      <div class="float-end">
        <router-link :to="{ name: 'OfferingInvestments', params: { id: id } }"
          ><h4>&times;</h4></router-link
        >
      </div>
      <h6>Edit Investment</h6>
      <div class="form-outline mb-4">
        <label>Investor Name</label>
        <input
          type="text"
          required
          v-model="investment.investorName"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Investor ID</label>
        <input
          type="text"
          required
          v-model="investment.investorId"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Amount</label>
        <input
          type="text"
          required
          v-model="investment.amount"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>faAdministrationFee</label>
        <input
          type="text"
          required
          v-model="investment.faAdministrationFee"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>faEquityShareCount</label>
        <input
          type="text"
          required
          v-model="investment.faEquityShareCount"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>faEquitySharePrice</label>
        <input
          type="text"
          required
          v-model="investment.faEquitySharePrice"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>faFundsTransferMethod</label>
        <input
          type="text"
          required
          v-model="investment.faFundsTransferMethod"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>faInvestmentId</label>
        <input
          type="text"
          required
          v-model="investment.faInvestmentId"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>faStatus</label>
        <input
          type="text"
          required
          v-model="investment.faStatus"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>offeringId</label>
        <input
          type="text"
          required
          v-model="investment.offeringId"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Offering Name</label>
        <input
          type="text"
          required
          v-model="investment.offeringName"
          class="form-control"
        />
      </div>
      <div class="error">{{ formError }}</div>
      <div v-if="!isPending" class="row text-center">
        <div class="col">
          <button class="btn btn-primary">Save</button>
        </div>
        <div class="col">
          <router-link
            :to="{ name: 'OfferingInvestments', params: { id: id } }"
            class="btn btn-secondary"
          >
            Cancel
          </router-link>
        </div>
        <div class="col">
          <span class="btn btn-link text-danger" @click="handleDelete">
            Delete Investment
          </span>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-secondary" disabled>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Saving...</span>
          </div>
        </button>
      </div>
    </form>
  </main>
</template>

<script>
import { ref } from "@vue/reactivity";
import SubNav from "@/components/navigation/SubNav.vue";
import useDocument from "@/composables/useDocument";
import getDocument from "@/composables/getDocument";
import { useRouter } from "vue-router";
import { timestamp } from "@/firebase/config";

export default {
  props: ["id", "investmentId"],
  components: { SubNav },
  setup(props) {
    const isPending = ref(false);
    const formError = ref(null);
    const router = useRouter();
    const { document: investment } = getDocument(
      "investments",
      props.investmentId
    );
    const { error: deleteError, deleteDoc: deleteInvestment } = useDocument(
      "investments",
      props.investmentId
    );

    const { error: memberError, updateDoc: updateMember } = useDocument(
      "investments",
      props.investmentId
    );

    const saveDetails = async () => {
      await updateMember({
        investorName: investment.value.investorName,
        investorId: investment.value.investorId,
        amount: investment.value.amount,
        faAdministrationFee: investment.value.faAdministrationFee
          ? investment.value.faAdministrationFee
          : "",
        faEquityShareCount: investment.value.faEquityShareCount
          ? investment.value.faEquityShareCount
          : "",
        faEquitySharePrice: investment.value.faEquitySharePrice
          ? investment.value.faEquitySharePrice
          : "",
        faFundsTransferMethod: investment.value.faFundsTransferMethod
          ? investment.value.faFundsTransferMethod
          : "",
        faInvestmentId: investment.value.faInvestmentId
          ? investment.value.faInvestmentId
          : "",
        faStatus: investment.value.faStatus ? investment.value.faStatus : "",
        offeringId: investment.value.offeringId,
        offeringName: investment.value.offeringName
          ? investment.value.offeringName
          : "",
        editedAt: timestamp()
      });
    };

    const handleSave = async () => {
      try {
        isPending.value = true;
        formError.value = "";
        await saveDetails();
        isPending.value = false;
        if (memberError.value) {
          formError.value = memberError.value;
          //TODO delete image here
        } else {
          console.log("Member Saved");
          router.push({
            name: "OfferingInvestments",
            params: { id: props.id }
          });
        }
      } catch (err) {
        formError.value = "System Error: " + err.message;
      }
    };

    const handleDelete = async () => {
      isPending.value = true;
      formError.value = null;
      await deleteInvestment();
      if (deleteError.value) {
        formError.value = deleteError.value;
      } else {
        router.push({ name: "OfferingInvestments", params: { id: props.id } });
      }
    };

    return {
      isPending,
      formError,
      handleSave,
      handleDelete,
      investment
    };
  }
};
</script>

<style>
</style>