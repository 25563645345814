<template>
  <SubNav class="subnav-position" :selected="'documents'" :id="id" />
  <main>
    <form @submit.prevent="handleSubmit" class="shadow edit-form">
      <div class="float-end">
        <router-link :to="{ name: 'OfferingDocuments', params: { id: id } }"
          ><h4>&times;</h4></router-link
        >
      </div>
      <h6>Upload Deal Documents</h6>
      <div class="form-outline mb-4">
        <label>Name</label>
        <input type="text" required v-model="name" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Description</label>
        <textarea v-model="description" class="form-control"> </textarea>
      </div>
      <div class="error">{{ formError }}</div>
      <div v-if="!isPending" class="row text-center">
        <div class="col">
          <button class="btn btn-primary">Create</button>
        </div>
        <div class="col">
          <router-link
            :to="{ name: 'OfferingDocuments', params: { id: id } }"
            class="btn btn-secondary"
          >
            Cancel
          </router-link>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-secondary" disabled>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Creating...</span>
          </div>
        </button>
      </div>
    </form>
  </main>
  <Footer />
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import SubNav from "@/components/navigation/SubNav.vue";
import useCollection from "@/composables/useCollection";
import { timestamp } from "@/firebase/config";
import Footer from "@/components/navigation/Footer.vue";
import getDocument from "@/composables/getDocument";

export default {
  props: ["id"],
  components: { SubNav, Footer },
  setup(props) {
    const isPending = ref(false);
    const formError = ref(null);
    const name = ref("");
    const description = ref("");
    const router = useRouter();
    const { error, addDoc } = useCollection("offeringDocuments");
    const { document: offering } = getDocument("offerings", props.id);

    const handleSubmit = async () => {
      try {
        isPending.value = true;
        formError.value = null;
        const res = await addDoc({
          name: name.value,
          description: description.value,
          offeringId: props.id,
          disclosure: false,
          issuerId: offering.value.issuerId,
          createdAt: timestamp()
        });
        isPending.value = false;
        if (error.value) {
          formError.value = error.value;
        } else {
          router.push({
            name: "EditDocument",
            params: { id: props.id, documentId: res.id }
          });
        }
      } catch (err) {
        formError.value = "System Error: " + err.message;
      }
    };

    return {
      name,
      description,
      isPending,
      formError,
      handleSubmit
    };
  }
};
</script>

<style>
</style>