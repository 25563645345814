  <template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="confirmVoidModal"
    tabindex="-1"
    aria-labelledby="confirmVoidModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="confirmVoidModalLabel">
            {{ title }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Keep
          </button>
          <button
            type="button"
            class="btn btn-danger"
            data-bs-dismiss="modal"
            @click="$emit('confirm-void')"
          >
            Void
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: ["title"]
};
</script>
  
  <style>
</style>