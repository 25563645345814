<template>
  <SubNav class="subnav-position" :selected="'investments'" :id="id" />
  <main>
    <div v-if="investments" class="subnav-detail">
      <div class="row">
        <div class="col">
          <router-link class="btn btn-primary" :to="{ name: 'CreateInvestment', params: { id: id } }">
            Create Investment
          </router-link>
        </div>
        <div class="col">
          <!-- TODO Maybe implement this to process by batch? -->
          <button v-if="!isInvesting" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#confirmCloseModal"
            disabled>
            Close Investments
          </button>
        </div>
      </div>
      <div v-if="error">{{ error }}</div>
      <div v-for="investment in investments" :key="investment.id">
        <router-link class="offering-link" :to="{
          name: 'InvestmentDetails',
          params: {
            offeringId: investment.offeringId,
            investmentId: investment.id
          }
        }">
          <div class="row single " :class="{
            newInvestment: investment.status == 'created',
            c2mError: investment.status == 'C2MError',
            cancelInvestment: investment.status == 'cancelled',
            acceptedInvestment: investment.status == 'accepted'
          }">
            <div v-if="investment.investmentType == 'entity'" class="name col-md">
              {{ investment.entityName }}
            </div>
            <div v-else class="name col-md">
              {{ investment.primaryFullName }}
            </div>
            <div class="name col-md">
              {{ investment.offeringName }}
            </div>
            <div class="name col-md">
              {{ investment.investmentType }}
            </div>
            <div class="name col-md">
              ${{ numberWithCommas(investment.totalInvestment) }}
            </div>
            <div class="name col-md">
              {{ investment.status }}
            </div>
            <div class="name col-md-1">{{ investment.paymentMethod }}</div>
            <div class="name col-md-3">
              {{ investment.createdAt.toDate().toLocaleString() }}
            </div>
          </div>
        </router-link>
      </div>
      <div class="text-center" v-if="investments && investments.length == 0">
        <p>
          This is where your investors will be listed when someone invests in
          this offering.
        </p>
      </div>
    </div>
    <ModalClose title="Close All Investments?" @confirm-close="handleCloseInvestment">
      Are you ready to move all of the funds from escrow into the offering? This will send a FINRA required email to the
      investor with an 'accepted' investment and cannot be undone.
    </ModalClose>
  </main>
</template>

<script>
import SubNav from "@/components/navigation/SubNav.vue";
import ModalClose from "@/components/modals/ModalClose.vue";
import getCollection from "@/composables/getCollection";
import usePrettyNumbers from "@/composables/usePrettyNumbers";
import { ref } from "@vue/reactivity";

export default {
  props: ["id"],
  components: { SubNav, ModalClose },
  setup(props) {
    const isInvesting = ref(false);

    const { error, documents: investments } = getCollection(
      "investments",
      [["offeringId", "==", props.id]],
      ["createdAt", "desc"]
    );

    const { numberWithCommas } = usePrettyNumbers();

    const handleCloseInvestment = async () => {
      isInvesting.value = true;
      try {
        console.log(`Close Accepted Investments`);
        // Need to handle Joint and Entity Investment
      } catch (err) {
        error.value = `Error: ${err.message}`;
      }
      isInvesting.value = false;

    };

    return {
      investments,
      isInvesting,
      error,
      handleCloseInvestment,
      numberWithCommas
    };
  }
};
</script>

<style></style>