<template>
  <main>
    <form v-if="policy" @submit.prevent="handleSubmit" class="shadow edit-form">
      <div class="float-end">
        <router-link :to="{ name: 'PrivacyPolicies' }"
          ><h4>&times;</h4></router-link
        >
      </div>
      <h4>Privacy Policy</h4>
      <div class="form-outline mb-4">
        <label>Details</label>
        <textarea
          placeholder="Privacy Policy"
          v-model="policy.details"
          class="form-control long-text"
        />
      </div>
      <h5>Preview</h5>
      <p class="story-text border p-2" v-html="policy.details"></p>
      <div class="error">{{ formError }}</div>
      <div v-if="isPending">
        <button class="btn btn-primary" disabled>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Saving New Privacy Policy...</span>
          </div>
        </button>
      </div>
      <div v-else class="row text-center">
        <div class="col">
          <button class="btn btn-primary">Save New Privacy Policy</button>
        </div>
        <div class="col">
          <router-link
            :to="{ name: 'PrivacyPolicies' }"
            class="btn btn-secondary"
          >
            Cancel
          </router-link>
        </div>
      </div>
    </form>
  </main>
</template>

<script>
import { useRouter } from "vue-router";
import getDocument from "@/composables/getDocument";
import useCollection from "@/composables/useCollection";
import getUser from "@/composables/getUser";
import { timestamp } from "@/firebase/config";
import { ref } from "@vue/reactivity";
import { format } from "date-fns";
export default {
  props: ["id"],
  setup(props) {
    const formError = ref(null);
    const isPending = ref(false);
    const { document: policy } = getDocument("rhPrivacy", props.id);
    const { error: docError, addDoc } = useCollection("rhPrivacy");
    const { user } = getUser();
    const router = useRouter();

    const handleSubmit = async () => {
      try {
        isPending.value = true;
        formError.value = null;
        await addDoc({
          details: policy.value.details,
          date: format(new Date(), "MMM d, uuuu HH:mm:ss"),
          createdBy: user.value.uid,
          createdAt: timestamp()
        });
        if (docError.value) {
          formError.value = docError.value;
        } else {
          router.push({
            name: "PrivacyPolicies"
          });
        }
        isPending.value = false;
      } catch (err) {
        formError.value = "System Error: " + err.message;
      }
    };

    return { formError, isPending, policy, handleSubmit };
  }
};
</script>

<style scoped>
.story-text {
  overflow-y: auto;
  max-height: 300px;
  text-decoration: none;
}
</style>