import { ref } from 'vue';
import { projectAuth } from '../firebase/config';

const error = ref(null);
const isPending = ref(false);

const login = async (email, password) => {
  error.value = null;
  isPending.value = true;


  try {
    const res = await projectAuth.signInWithEmailAndPassword(email, password);
    res.user.getIdTokenResult().then((getIdTokenResult) => {
      if (getIdTokenResult.claims.admin) {
        error.value = null;
        isPending.value = false;
        return res;
      } else {
        projectAuth.signOut();
        error.value = 'Incorrect Login Permission';
        isPending.value = false;
      }
    });
  }
  catch (err) {
    console.log(err.message);
    error.value = 'Incorrect Login Credentials';
    isPending.value = false;
  }


};

const useLogin = () => {
  return { error, login, isPending };
};

export default useLogin;