<template>
  <header>
    <nav class="navbar fixed-top navbar-expand-md navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" :href="homePage"
          ><img id="navbar-logo" src="@/assets/img/RoundHere_logo_152x80.png"
        /></a>
        <button
          v-if="user"
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div v-if="user" class="collapse navbar-collapse" id="navbarCollapse">
          <ul class="navbar-nav ms-auto mb-2 mb-md-0 d-flex">
            <li class="nav-item">
              <span
                class="nav-link btn"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                @click="closeMenuRoute('Issuers')"
                >Issuers</span
              >
            </li>
            <li class="nav-item">
              <span
                class="nav-link btn"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                @click="closeMenuRoute('Investors')"
                >Investors</span
              >
            </li>
            <li class="nav-item">
              <span
                class="nav-link btn"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                @click="closeMenuRoute('Offerings')"
                >Offerings</span
              >
            </li>
            <li class="nav-item">
              <span
                class="nav-link btn"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                @click="closeMenuRoute('Documents')"
                >Documents</span
              >
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Account
              </a>
              <ul
                class="dropdown-menu dropdown-menu-end text-center"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <span
                    class="nav-link btn"
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                    @click="closeMenuRoute('Admins')"
                    >Admins</span
                  >
                </li>
                <li>
                  <span
                    class="nav-link btn"
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                    @click="closeMenuRoute('CarouselItems')"
                    >Carousel</span
                  >
                </li>
                <li>
                  <span
                    class="nav-link btn"
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                    @click="closeMenuRoute('Features')"
                    >Features</span
                  >
                </li>
                <li>
                  <span
                    class="nav-link btn"
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                    @click="closeMenuRoute('Founders')"
                    >Founders</span
                  >
                </li>
                <li>
                  <span
                    class="nav-link btn"
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                    @click="closeMenuRoute('News')"
                    >News</span
                  >
                </li>
                <li>
                  <span
                    class="nav-link btn"
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                    @click="closeMenuRoute('Terms')"
                    >Terms</span
                  >
                </li>
                <li>
                  <span
                    class="nav-link btn"
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                    @click="closeMenuRoute('PrivacyPolicies')"
                    >Privacy</span
                  >
                </li>
                <li><hr class="dropdown-divider" /></li>
                <li>
                  <button class="btn btn-primary" @click="handleClick">
                    Sign Out
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
import { ref } from "vue";
import useLogout from "@/composables/useLogout";
import { useRouter } from "vue-router";
import getUser from "@/composables/getUser";

export default {
  setup() {
    const { logout, error } = useLogout();
    const router = useRouter();
    // const isScrolled = ref(false);
    const { user } = getUser();
    const homePage = ref(process.env.VUE_APP_HOME_PAGE);

    const handleClick = async () => {
      await logout();
      if (!error.value) {
        router.push({ name: "Login" });
      } else {
        console.log("Error logging out: ", error.value);
      }
    };

    // const handleScroll = () => {
    //   isScrolled.value =
    //     document.body.scrollTop > 50 || document.documentElement.scrollTop > 50;
    // };

    // onMounted(() => {
    //   window.addEventListener("scroll", handleScroll);
    // });

    // onUnmounted(() => {
    //   window.removeEventListener("scroll", handleScroll);
    // });

    const closeMenuRoute = routeName => {
      router.push({ name: routeName });
    };

    return { handleClick, user, homePage, closeMenuRoute };
  }
};
</script>
<style>
/* HEADER / TOP MENU
------------------------------------------------- */

#navbar-logo {
  height: 40px;
  transition-property: height;
  transition-duration: 0.5s;
}

#navbar-logo.small {
  height: 40px;
}

li.nav-item {
  text-align: center;
  margin-right: 5px;
}

.navbar-expand-md .navbar-nav .nav-link {
  margin-top: 6px;
  color: #404042;
  font-weight: 600;
  text-transform: uppercase;
}
</style>
